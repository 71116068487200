import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import varColor from '../data/varColor';

const ToastContext = createContext();

export const ToastProvider = ({ children, theme }) => {

    const showToast = (message, type) => {
        if(type === "success"){
            toast.success(message, {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText }});
        }
        if(type=== "error"){
            toast.error(message, {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText }});
        }
        // setTimeout(() => {
        //     toast.success(message);
        // }, delay);      
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
        </ToastContext.Provider>
    );
};




export const useToast = () => {
    return useContext(ToastContext);
};
