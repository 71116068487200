import React from 'react';
import { Helmet } from 'react-helmet-async';

function Privacy() {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy | BokenMap</title>
            </Helmet>

            <h1>Privacy Policy</h1>

            <h4>Last Updated: Dec 10, 2024</h4>

            BokenMap is committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and safeguard the information you provide to us on our website and through our web services.

            <h2>1.Information We Collect</h2>
            We may collect personal information that you voluntarily provide to us, such as your name, email address, and other contact information. We may also collect non-personal information, such as your IP address, browser type, and usage data.

            <h2>2.Use of Information</h2>
            We use the information we collect to provide and improve our web services, respond to your inquiries, and communicate with you about updates and promotions. We may also use the information for analytical purposes to enhance our services and improve user experience.

            <h2>3.Data Sharing and Disclosure</h2>
            We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our website and delivering our services. These third parties are obligated to maintain the confidentiality and security of your information.
            We may also disclose your information if required by law, regulation, or legal process, or if we believe disclosure is necessary to protect our rights, property, or safety.

            <h2>4.Data Security</h2>
            We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.

            <h2>5.Cookies and Tracking Technologies</h2>
            We may use cookies and other tracking technologies to enhance your experience on our website and provide you with relevant content and advertisements. You can adjust your browser settings to control or disable these technologies.

            <h2>6.Third-Party Links</h2>
            Our website may contain links to third-party websites or services that have separate and independent privacy policies. We are not responsible for the content or privacy practices of these third parties.

            <h2>7.Children's Privacy</h2>
            Our website and services are not intended for children under the age of 18. We do not knowingly collect personal information from children without parental consent.

            <h2>8.Changes to this Policy</h2>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically for any updates.

            <br />
            By using our website and services, you acknowledge that you have read and understood this Privacy Policy.



        {/* <h3>Any questions or comments? Please reach out from <a href="/contact">here</a>.</h3> */}

    
            
        </div>
    );
}

export default Privacy;