export const getColorSet = (option) => {
    const colorSets = {
      "green": {
        lived: "var(--color-lived-green)",
        livedHex: "005403",
        visited: "var(--color-visited-green)",
        visitedHex: "00cf18",
        transited: "var(--color-transited-green)",
        transitedHex: "bbff00",
        ui: "var(--color-ui-green)",
        uiHex: "0d780d",
      },
      "blue": {
        lived: "var(--color-lived-blue)",
        livedHex: "093788",
        visited: "var(--color-visited-blue)",
        visitedHex: "3069e4",
        transited: "var(--color-transited-blue)",
        transitedHex: "71d7ff",
        ui: "var(--color-ui-blue)",
        uiHex: "0b40d1",
      },
      "red": {
        lived: "var(--color-lived-red)",
        livedHex: "a20606",
        visited: "var(--color-visited-red)",
        visitedHex: "f14a4a",
        transited: "var(--color-transited-red)",
        transitedHex: "ff9a9a",
        ui: "var(--color-ui-red)",
        uiHex: "ee1d1d",
      },
      "purple": {
        lived: "var(--color-lived-purple)",
        livedHex: "71025d",
        visited: "var(--color-visited-purple)",
        visitedHex: "c632c1",
        transited: "var(--color-transited-purple)",
        transitedHex: "f49dff",
        ui: "var(--color-ui-purple)",
        uiHex: "cf09be",
      },
      "brown": {
        lived: "var(--color-lived-brown)",
        livedHex: "642f00",
        visited: "var(--color-visited-brown)",
        visitedHex: "b86318",
        transited: "var(--color-transited-brown)",
        transitedHex: "ffbd6d",
        ui: "var(--color-ui-brown)",
        uiHex: "834d06",
      },
    };

    return colorSets[option] || colorSets[1]; // Default to set 1
  };
  
