import React, { useEffect, useState, useRef } from 'react';
import EmblaCarousel from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';

const LandingCarousel = ({theme, currentSlide, setCurrentSlide}) => {
    // const [currentSlide, setCurrentSlide] = useState(0);
  const emblaRef = useRef(null);
  const emblaInstance = useRef(null);

  useEffect(() => {
    if (emblaRef.current) {
      const autoplay = Autoplay({ delay: 3000, stopOnInteraction: false });
      emblaInstance.current = EmblaCarousel(emblaRef.current, { loop: true }, [autoplay]);


      const onSelect = () => {
        if (emblaInstance.current) {
          setCurrentSlide(emblaInstance.current.selectedScrollSnap());
        }
      };

      emblaInstance.current.on('select', onSelect);



      // Optional: Add click behavior for images
      const handleImageClick = () => {
        if (emblaInstance.current) {
          emblaInstance.current.scrollNext(); // Move to the next slide
        }
      };

      const images = emblaRef.current.querySelectorAll('.carousel-image');
      images.forEach((image) => {
        image.addEventListener('click', handleImageClick);
      });

      return () => {
        // Cleanup event listeners and carousel instance
        images.forEach((image) => {
          image.removeEventListener('click', handleImageClick);
        });
        if (emblaInstance.current) {
          emblaInstance.current.destroy();
        }
      };
    }
  }, []);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        <div className="embla__slide">
          <img
            className="carousel-image"
            src={theme === "dark" ? "https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" : "https://pbs.twimg.com/media/GdZl878aMAAJzwF?format=jpg&name=large"}
            alt="Country"
          />
        </div>
        <div className="embla__slide">
          <img
            // className="carousel-image"
            className={theme === "dark" ? "carousel-image" : "carousel-image carousel-city-light"}
            src={theme === "dark" ? "https://pbs.twimg.com/media/GeBAp3FbUAAI3Bq?format=jpg&name=large" : "https://pbs.twimg.com/media/GeBAp3CagAAw2hK?format=jpg&name=large"}
            alt="City"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCarousel;
