import React from 'react';
import { Helmet } from 'react-helmet-async';

function Extra() {
    return (
        <div className='page' style={{display: "flex", flexDirection: "column"}}>
            <Helmet>
                <title>Extra | BokenMap</title>
            </Helmet>
            <h1>Extra</h1>
            <p>This page shows extra information and tips for BokenMap.</p>

            <h2>Useful Links</h2>
            <div style={{display: "flex", flexDirection: "column"}}>
                <p>You can add ?type= country or city to specify which to go to. If not specified, it will show the country page.</p>
                <a href={`${process.env.REACT_APP_FRONT_DOMAIN}/hikaru?type=country`}>bokenmap.com/HANDLE?type=country</a>
                <p>Go directly to the city page</p>
                <a href={`${process.env.REACT_APP_FRONT_DOMAIN}/hikaru?type=city`}>bokenmap.com/HANDLE?type=city</a>
            </div>


            <h2>Country List</h2>
        </div>
    );
}

export default Extra;