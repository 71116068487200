import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';

import toast, { Toaster } from 'react-hot-toast';

import Error from '../components/Error';
import varColor from '../data/varColor';

import { getColorSet } from '../components/getColorSet';

import "../stylesheets/Share.css"

function Share({ theme }) {
    const { handle } = useParams();
    const navigate = useNavigate();

    const [homeUser, setHomeUser] = useState();
    const [loading, setLoading] = useState(true); // New loading state

    const [homeColors, setHomeColors] = useState(getColorSet("green"))

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${handle}`, { method: "GET", credentials: "include" })
            .then(response => response.json())
            .then(data => {
                if (data.exist) {
                    setHomeUser(data);
                    setHomeColors(getColorSet(data.homeUser.color || "green"))
                } else {
                    setHomeUser(null);
                }
            })
            .catch(error => {
                console.error(error);
                setHomeUser(null);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when the fetch completes
            });
    }, [handle]);

    function copyLink(handle) {
        const link = `https://bokenmap.com/${handle}`;
        navigator.clipboard.writeText(link).then(() => {
            toast.success("Link copied to clipboard!", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}});
        }, (err) => {
            console.error("Unable to copy embed code: ", err);
            toast.error("Failed to copy link.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}});
        });
    }

    if (loading) {
        return (
            <div className="page">
                <Helmet>
                    <title>Loading | BokenMap</title>
                </Helmet>
                <p>Loading...</p> {/* Add a loading indicator */}
            </div>
        );
    }

    return (
        <div className="share-page">
            <Helmet>
                <title>Share | BokenMap</title>
            </Helmet>

            {homeUser ? (
                <div className='back-icon-container'>
                    <FontAwesomeIcon icon={faArrowLeft} className="back-icon" onClick={() => { navigate(`/${handle}`); }} />
                </div>
            ) : null}

            {homeUser ? (
                <div className='share-page-content'>
                    <div className="share-profile">
                        <div className="share-qrcode-container">
                            <img
                                src={
                                    theme === "dark"
                                        ? `https://api.qrserver.com/v1/create-qr-code/?data=https://bokenmap.com/${handle}&amp;size=300x300&color=07a707&bgcolor=303030`
                                        : `https://api.qrserver.com/v1/create-qr-code/?data=https://bokenmap.com/${handle}&amp;size=300x300&color=0d780d&bgcolor=f1f3f3`
                                }
                                alt=""
                                title=""
                                className="share-qrcode"
                                onClick={() => { navigate(`/${handle}`); }}
                            />
                            <p className="share-at"><span style={{ marginRight: "4px" }}>@</span>{handle}</p>
                        </div>
                        <div className="share-logo">
                            <div className="share-logo-icon"></div>
                            <div className="share-logo-title">BokenMap</div>
                        </div>
                    </div>
                    <div className="share-bottom-row">
                        <div className="share-bottom-left" onClick={() => { navigate(`/${handle}`); }}>
                            <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                            <div className="left-text">Go to Profile</div>
                        </div>
                        <div className="share-bottom-right" onClick={() => { copyLink(handle); }}>
                            <FontAwesomeIcon icon={faLink} className="link-icon" />
                            <div className="right-text">Copy Link</div>
                        </div>
                    </div>
                </div>
            ) : <Error />}
        </div>
    );
}

export default Share;
