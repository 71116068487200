import React from 'react';
import { Helmet } from 'react-helmet-async';

function About({theme, handleModalOpen}) {
    return (
        <div className='page'>
            <Helmet>
                <title>About | BokenMap</title>
            </Helmet>
            <h1>About</h1>
            <h2>What is BokenMap?</h2>
            <p>BokenMap is a tool to map the places you have been to. Both countries and cities are supported.</p>

            <p>This isn't a temporary service to create an image, but a place where you can keep updating your travels. In addition to having a <a href="/hikaru" className='about-link'>profile page</a>, you can embed the map in other websites, like your blog.</p>

            {theme === "dark" ? 
                        <img src="https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" alt="" className='about-map-image' /> : 
                        <img src='https://pbs.twimg.com/media/GdZl878aMAAJzwF?format=jpg&name=large' alt='' className='about-map-image' />}
            <figcaption className='about-figcaption'>You can make/embed something like this</figcaption>

            {theme === "dark" ? 
                        <img src="https://pbs.twimg.com/media/GeBAp3FbUAAI3Bq?format=jpg&name=large" alt="" className='about-map-image' /> : 
                        <img src='https://pbs.twimg.com/media/GeBAp3CagAAw2hK?format=jpg&name=large' alt='' className='about-map-image' />}
            <figcaption className='about-figcaption'>or like this, for cities</figcaption>


            <h2>How to Share</h2>
            <p>There are 2 ways to share, either by Link or by Embed.</p>

            <p>Link is simple. Just copy paste your profile page link to any social media bio. It's "bokenmap.com/" + your handle name.</p>
            <a href="/hikaru">https://bokenmap.com/hikaru</a>

            <p>Embedding is quite easy as well. By clicking the embed code button on the bottom right of the map, an iframe embed code will be copied to your clipboard. Paste that in any website you please, and voilà!</p>

            <iframe src={`${process.env.REACT_APP_FRONT_DOMAIN}/embed/country/hikaru?theme=dark`} frameborder="0" width="100%" height="auto" style={{margin: "auto", display: "flex"}} className='about-embed-iframe'></iframe>
            <iframe src={`${process.env.REACT_APP_FRONT_DOMAIN}/embed/city/hikaru?theme=dark`} frameborder="0" width="100%" height="auto" style={{margin: "auto", marginTop: "10px", display: "flex"}} className='about-embed-iframe'></iframe>

            <div className='about-get-started-container'>
                <div onClick={handleModalOpen} className='about-get-started-button' style={{color: "white"}}>Get Started</div>
            </div>
        </div>
    );
}

export default About;