import React from 'react';
import { useNavigate } from 'react-router-dom';

import "../stylesheets/Error.css"

function Error() {
    const navigate = useNavigate()

    return (
        <div className='error-container'>
            {/* 絵 */}
            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/The_Fighting_Temeraire%2C_JMW_Turner%2C_National_Gallery.jpg/606px-The_Fighting_Temeraire%2C_JMW_Turner%2C_National_Gallery.jpg' className='error-image'></img>
            {/* <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/La_Gare_Saint-Lazare_-_Claude_Monet.jpg/1920px-La_Gare_Saint-Lazare_-_Claude_Monet.jpg' className='error-image'></img> */}
            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Fromentin_-_Campement_dans_le_Sahara.jpg/2880px-Fromentin_-_Campement_dans_le_Sahara.jpg" alt="" className='error-image' style={{height: "250px", objectFit: "cover", objectPosition: "bottom"}}></img> */}

            {/* コンパス */}
            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Compass_rose_Cantino.svg/1920px-Compass_rose_Cantino.svg.png" className='error-image' style={{width: "200px"}} alt="" /> */}
            {/* <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/WInd_Rose_Aguiar.svg/1920px-WInd_Rose_Aguiar.svg.png' className='error-image' style={{width: "200px"}}></img> */}

            {/* 地図 */}
            {/* <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Map_of_Paris_by_Claes_Jansz._Visscher_-_Harold_B._Lee_Library.jpg/1920px-Map_of_Paris_by_Claes_Jansz._Visscher_-_Harold_B._Lee_Library.jpg' className='error-image'></img> */}

            {/* <img src='' className='error-image'></img> */}
            <h1>The page you're looking for does not exist<span style={{color: "#0d780d", fontSize: "39px", lineHeight: "20px"}}>.</span></h1>
            <div onClick={() => navigate(`/`)} className='error-return-button'>Return</div>
        </div>
    );
}

export default Error;