import React from 'react';

function CountryTable({ sortedCountries, sortCountries, getFlagEmoji, homeColors }) {
    return (
        <div className='home-country-list' style={{marginTop: "15px"}}>
            <table className='home-country-table'>
                <thead>
                    <tr>
                        <th className='country-th-status' onClick={() => sortCountries('status')}>
                            <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Status
                        </th>
                        <th className='country-th-country' onClick={() => sortCountries('name')}>
                            <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Country
                        </th>
                        <th className='country-th-continent' onClick={() => sortCountries('continent')}>
                            <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Continent
                        </th>
                    </tr>
                </thead>
                <tbody className='tbody'>
                {sortedCountries.map((country, index) => (
                <tr key={index}>
                    <td className='country-td-status'>
                        {country.status == "1" && (<div className='status-one status-icon' style={{backgroundColor: homeColors.lived}}></div>)}
                        {country.status == "2" && (<div className='status-two status-icon' style={{backgroundColor: homeColors.visited}}></div>)}
                        {country.status == "3" && (<div className='status-three status-icon' style={{backgroundColor: homeColors.transited}}></div>)}
                    </td>
                    <td className='country-td-country'><span className='flag-emoji'>{getFlagEmoji(country.shortName)}</span>{country.name}</td>
                    <td className='country-td-continent'>{country.continent}</td>
                </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default CountryTable;