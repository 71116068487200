
import React from "react";
import { NavLink } from "react-router-dom";

import "../../stylesheets/Notice.css";

const Notice = ({ children }) => {
    return (
        <div className="page">
            <div className="notice-nav">
                <NavLink to="/terms" className={({ isActive }) => isActive ? "notice-nav-tab notice-nav-tab-active" : "notice-nav-tab"}>Terms</NavLink>
                <div className="notice-nav-tab-spacer"></div>
                <NavLink to="/privacy" className={({ isActive }) => isActive ? "notice-nav-tab notice-nav-tab-active" : "notice-nav-tab"}>Privacy</NavLink>
                <div className="notice-nav-tab-spacer"></div>
                <NavLink to="/commerce" className={({ isActive }) => isActive ? "notice-nav-tab notice-nav-tab-active" : "notice-nav-tab"}>Commerce</NavLink>
            </div>

            <div>{children}</div>
        </div>
    );
};

export default Notice;
