import React from 'react';
import { Helmet } from 'react-helmet-async';

function Commerce() {

    return (
        <div>
            <Helmet>
                <title>Commercial Disclosure | BokenMap</title>
            </Helmet>

            {/* <h1>Legal Notice in Accordance with the Act on Specified Commercial Transactions</h1> */}
            <h1>Commercial Disclosure</h1>
            <h3>特定商取引法に基づく表示</h3>

            <h4>Last Updated: Dec 10, 2024</h4>

            <h2>Seller</h2>
            Hikaru Imai

            <h2>Address</h2>
            Will be disclosed without delay if requested.

            <h2>Phone Number</h2>
            Will be disclosed without delay if requested.

            <h2>Contact</h2>
            <a href="/contact">From this page</a>

            <h2>Head of Operations</h2>
            Hikaru Imai

            <h2>Pricing</h2>
            On the pricing page and during the purchase process, prices are displayed inclusive of tax and fees.

            <h2>Additional Fees</h2>
            Customers are responsible for the internet communication fees required to use this service.

            <h2>Exchanges & Returns Policy</h2>
            Cancellations and refunds cannot be accepted for reasons attributable to the customer.

            <h2>Delivery Times</h2>
            The service will be available immediately after the payment process is completed.

            <h2>Accepted Payment Methods</h2>
            Payment can be made by credit card or other methods specified by us. The available payment options will be displayed on the purchase process screen.

            <h2>Payment Period</h2>
            Payment is confirmed at the time of order. Subscription payments will be made monthly. 


            
        </div>
    );
}

export default Commerce;