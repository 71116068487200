import {React, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Select from "react-select"
import AsyncSelect from 'react-select/async';

import { useToast } from '../components/ToastContext';
import { Toaster, toast } from 'react-hot-toast';

import CountryData from "../data/country.json"
import varColor from "../data/varColor.json"

function Profile({theme, user}) {
    const [selectedHomeOption, setSelectedHomeOption] = useState(user.user.home || '');
    const [selectedNationalityOption, setSelectedNationalityOption] = useState(user.user.nationality || '');
    const [image, setImage] = useState(user.user.image || '');
    const [username, setUsername] = useState(user.user.username || '');
    const [desc, setDesc] = useState(user.user.desc || '');
    const [website, setWebsite] = useState(user.user.website || '');

    const navigate = useNavigate()
    const { showToast } = useToast();


    const nationalityOptions = Object.keys(CountryData).map(key => ({
        value: key,
        label: CountryData[key].country
    }));

    const homeOptions = [
        {value: "Tokyo", label: "Tokyo"}
    ]

    const customStyles = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: theme === "dark" ? "1px solid #525252" : "1px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: theme === "dark" ? varColor.darkGray : "#fff", // Change background color
            color: theme === "dark" ? "#fff" : "black", // Default text color
            // borderColor: theme == "dark" ? '#666' : '#ccc', // Change border color on focus
            // borderColor: state.isFocused ? (theme == "dark" ? "#fff" : "#fff") : (theme == "dark" ? "#fff" : "#fff"),
            // borderColor: state.isSelected ? (theme == "dark" ? "#fff" : "#fff") : (theme == "dark" ? "#fff" : "#fff"),
            borderRadius: "5px",
            boxShadow: 'none',
            width: "200px",
            height: "30px",
            minHeight: "0px",
            maxHeight: "30px"
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '25px',
            // padding: '0 6px'
            paddingLeft: "5px",
            paddingTop: "0px",
            fontSize: "14px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: theme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 0px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'hidden', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            // backgroundColor: state.isSelected 
            //     ? (theme === "dark" ? "#333" : "#666")  // Color when selected
            //     : state.isFocused 
            //         ? (theme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
            //         : (theme === "dark" ? "#000" : "#fff"),  // Normal background
            backgroundColor: state.isFocused 
                    ? (theme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (theme === "dark" ? varColor.darkGray : "#fff"),  // Normal background
            // backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#e6e6e6' : '#fff', // Change background color on select/hover
            // backgroundColor: theme == "dark" ? "black" : "white",
            color: state.isSelected
                ? (theme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (theme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : theme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: theme==="dark" ? varColor.darkGray : '#fff', // Background color of the dropdown
            width: "200px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            // color: state.isFocused ? (theme == "dark" ? '#fff' : '#ccc') : (theme == "dark" ? '#fff' : '#ccc'),
            // color: state.isSelected ? (theme == "dark" ? '#fff' : '#ccc') : (theme == "dark" ? '#fff' : '#ccc'),
            // color: theme == "dark" ? '#fff' : '#ccc', // Customize the arrow color
            // color: theme === "dark" ? '#fff' : '#666',
            // color: state.isFocused ? "hsl(255, 255 , 255, 100%)" : "hsl(255, 255 , 255, 100%)",
            color: state.isSelected
            ? (theme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (theme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : theme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
        input: (provided) => ({
            ...provided,
            color: (theme === "dark" ? "white" : "#1E1E1E"), // Change this to your desired color
          }),
    };


    const handleHomeChange = (option) => {
        const selected = option?.value;
        setSelectedHomeOption(selected);
    };


    const [selectedCity, setSelectedCity] = useState(
        user.user.home? {
            label: `${user.user.home}, ${CountryData[user.user.homeCountry]?.country || ""}`,
            cityName: user.user.home,
            countryCode: user.user.homeCountry,
            lat: user.user.homeLat,
            lng: user.user.homeLong,
        }: {}
    );

    const fetchCities = async (inputValue) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/api/search/city?name=${inputValue}`);
          const data = await response.json();
  
          return data.geonames.map((city) => ({ value: city.geonameId, label: `${city.name}, ${city.countryName}`, cityName: city.name, countryName: city.countryName, countryCode: city.countryCode, lat: city.lat, lng: city.lng }));
        } catch (error) {
          console.error('Error fetching cities:', error);
          return [{value: "lived", label: "Lived"},];
        }
     };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };




    const handleNationalityChange = (option) => {
        const selected = option?.value;
        setSelectedNationalityOption(selected);
    };
    

    const handleSubmit = async (e) => {   
        e.preventDefault();

        const data = {
            image,
            username,
            desc,
            home: selectedCity.cityName,
            homeCountry: selectedCity.countryCode,
            homeLat: selectedCity.lat,
            homeLong: selectedCity.lng,
            nationality: selectedNationalityOption,
            website
        };
        

        try {
            const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/settings/profile`, {
                method: "POST",
                credentials: "include",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data)
            }); 

            if (response.ok) {
                // Handle success, e.g., show a success message or redirect
                // toast.success("Saved Profile")
                // showToast("Saved Profile")
                navigate(`/${user.user.handle}`)
                showToast("Saved Profile", "success")
            } else {
                const errorData = await response.json();
                const errorMessage = errorData.message.split(": ").pop(); // Extract only the description part
                console.error("Error updating profile:", response.statusText);
                // showToast("Failed to save profile.", "error")
                showToast(errorMessage, "error")
            }
        } catch (error) {
            console.error("An error occurred:", error);
            showToast("Failed to save profile.", "error")
        }
    };

    


    return (
        <div className='dashboard-right'>
            <Helmet>
                <title>Profile | BokenMap</title>
            </Helmet>
            <h1>Profile</h1>

            <form onSubmit={handleSubmit}>
                <div className='settings-each'>
                    <h3>Image</h3>
                    {/* <p style={{marginTop: "8px", fontSize: "14px"}}>これはまだ実装してない</p> */}
                    <div className='settings-each-content profile-image-content'>
                        <img src={user.user.image} style={{marginBottom: "15px"}}></img>
                        {/* <input type='file' className='image-upload-button'></input> */}
                        <input className='profile-input' type='text' value={image} onChange={(e) => setImage(e.target.value)}></input>
                    </div>
                </div>
                <div className='settings-each'>
                    <h3>Username</h3>
                    <p style={{marginTop: "8px", fontSize: "14px"}}></p>
                    <div className='settings-each-content'>
                        <input className='profile-input' type='text' value={username} onChange={(e) => setUsername(e.target.value)}></input>
                    </div>
                </div>
                <div className='settings-each'>
                    <h3>Description</h3>
                    <p style={{marginTop: "8px", fontSize: "14px"}}></p>
                    <div className='settings-each-content'>
                        <textarea className='input-desc' value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                    </div>
                </div>
                <div className='settings-each'>
                    <h3>Home</h3>
                    <p style={{marginTop: "8px", fontSize: "14px"}}></p>
                    <div className='settings-each-content'>
                        <AsyncSelect
                        value={selectedCity}
                        loadOptions={fetchCities}
                        className='country-select'
                         styles={customStyles}
                         isSearchable={true}
                         onChange={handleCityChange}
                         placeholder="Search City" />
                    </div>
                </div>
                <div className='settings-each'>
                    <h3>Nationality</h3>
                    <p style={{marginTop: "8px", fontSize: "14px"}}></p>
                    <div className='settings-each-content'>
                        <Select options={nationalityOptions} value={nationalityOptions.find(option => option.value === selectedNationalityOption)}  className='profile-nationality-select' placeholder="Select Country" onChange={handleNationalityChange} styles={customStyles} />
                    </div>
                </div>
                <div className='settings-each'>
                    <h3>Website</h3>
                    <p style={{marginTop: "8px", fontSize: "14px"}}></p>
                    <div className='settings-each-content'>
                        <input className='profile-input' type='text' value={website} onChange={(e) => setWebsite(e.target.value)}></input>
                    </div>
                </div>
                <div className='profile-save-button-container'>
                    <button type='submit' className='profile-save-button'>Save</button>
                </div>
            </form>
        </div>
    );
}

export default Profile;