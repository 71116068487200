
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EmojiSwitcher = ({ emojis, intervalTime = 2000 }) => {
  const navigate = useNavigate();
  const [currentEmojiIndex, setCurrentEmojiIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentEmojiIndex((prevIndex) => (prevIndex + 1) % emojis.length);
    }, intervalTime);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [emojis, intervalTime]);

  return <p onClick={()=>{navigate("/about")}}>{emojis[currentEmojiIndex]}</p>;
};

export default EmojiSwitcher;
