import React from 'react';
import { useEffect, useState, ref } from 'react';

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { geoCylindricalStereographic } from "d3-geo-projection";

import CountryTable from './CountryTable';

import { getColorSet } from './getColorSet';
import { getFlagEmoji } from './getFlagEmoji';


const CountryMap = React.forwardRef(({dataTheme, homeUser, embed, isFullScreen, sortCountries, sortedCountries, homeColors}, ref)=> {

    useEffect(() => {
        const root = am5.Root.new("chartdiv", {
            // モバイルでクソ画質にならないように
            useSafeResolution: false,
        });
        // このアニメーション設定めちゃ重要、最初のズレなくなり、スムーズになる
        root.setThemes([am5themes_Animated.new(root)]);

        // ロゴ削除　ライセンス的にダメだけど、物理的にはできる
        // root._logo.dispose();

        const chart = root.container.children.push(
            am5map.MapChart.new(root, {
                projection: geoCylindricalStereographic(),
                // projection: am5map.geoMercator(),
                panX: isFullScreen ? "rotateX" : "translateX",
                panY: "translateY",
                maxPanOut: 0.4,
                zoomStep: 2.1,
                minZoomLevel: 1.02,
                maxZoomLevel: 7,
                homeZoomLevel: 1.02,
                homeGeoPoint: { longitude: 10, latitude: 20 },
                wrapLongitude: true
            })
        );


        // let exporting = am5plugins_exporting.Exporting.new(root, {
        //     menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        //     pngOptions: {
        //         quality: 0.8,
        //         maintainPixelRatio: true
        //       }
        //   });
        

        const colorTheme = {
            // 緑
            // base: 0xd1d1d1,
            // base: 0x0C0C0C, //黒のベース大陸カラー
            base: dataTheme === "dark" ? 0x171717 : 0xcdcdcd,
            // border: 0x7d7d7d,
            border: dataTheme === "dark" ? 0x494949 : 0x959595 ,
            // background: 0xFAFAFA,
            // background: 0xFBFBFB,
            // background: 0xffffff,
            background: dataTheme === "dark" ? 0x303030 : 0xf4f4f4, //--dark-gray
            // background: 0x1B1B1D,
            lived: parseInt(`0x${homeColors.livedHex}`),
            // livedBorder: 0xff1605,
            visited: parseInt(`0x${homeColors.visitedHex}`),
            // visitedBorder: 0xff6505,
            transited: parseInt(`0x${homeColors.transitedHex}`),
            // transitedBorder: 0xffea05
        }

        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow, // 地図データを追加
                fill: am5.color(colorTheme.base),
                stroke: am5.color(colorTheme.border), // 国境の線の色
                exclude: ["AQ"], // 表示しない場所　AQは南極
            })
        );

        polygonSeries.events.on("datavalidated", function() {
            chart.goHome();
        });

        let pointSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
            latitudeField: "lat",
            longitudeField: "long"
        }));
          
        pointSeries.bullets.push(()=> {
            let circle = am5.Circle.new(root, {
              radius: 5,
              fill: am5.color(0xff0000),
              tooltipText: "{name}"
            });
          
            circle.events.on("click", function(ev) {
              alert("Clicked on " + ev.target.dataItem.dataContext.name)
            });
          
            return am5.Bullet.new(root, {
              sprite: circle
            });
        });

        chart.chartContainer.set("background", am5.Rectangle.new(root, {
            // 海の色 0xは固定で、その後にHex code
            fill: am5.color(colorTheme.background),
            fillOpacity: 1
        }));


        polygonSeries.mapPolygons.template.setAll({
            // hoverした時に表示される名前 {name}だと国名
            interactive: true,
            templateField: "polygonSettings",
            cursorOverStyle: "pointer",
        });
        polygonSeries.mapPolygons.template.adapters.add("tooltipText", function (text, target) {
            const countryId = target.dataItem?.get("id"); // Fetch country ID
            const countryName = target.dataItem?.dataContext.name; // Fetch country name
            return `${getFlagEmoji(countryId)}${countryName}`;
        });
        

          
          polygonSeries.mapPolygons.template.states.create("hover", {
            // hoverした時の国の色
            // fill: am5.color(0x677935),
            // strokeWidth: 3 
        });
          
          
          polygonSeries.mapPolygons.template.events.on("over", function(event) {
            event.target.zIndex = Number.MAX_VALUE;
            event.target.toFront();
        });


        polygonSeries.mapPolygons.template.events.on("click", (ev) => {
            const countryName = ev.target.dataItem.dataContext.name
            const wikiUrl = `https://en.wikipedia.org/wiki/${countryName}`
            window.open(wikiUrl, '_blank');
        })



        const shipArr = homeUser.countries.map(each=>{
            let fillColor
            if(each.status == 1){
                fillColor = colorTheme.lived
            }else if (each.status == 2){
                fillColor = colorTheme.visited
            }else if (each.status == 3){
                fillColor = colorTheme.transited
            }
            return {
                id: each.shortName,
                polygonSettings: {
                  fill: am5.color(fillColor),
                //   stroke: borderColor ? m5.color(borderColor) : null,
                //   strokeWidth: 2
                }
            }
        })
        polygonSeries.data.setAll(shipArr)

        chart.appear(800, 100);


        // Interval-based forced resize and centering
        const intervalId = setInterval(() => {
            root.resize();
            chart.goHome();
        }, 50);
        // Stop interval after short delay
        setTimeout(() => {
            clearInterval(intervalId);
            chart.goHome();
        }, 500);

        return () => {
            root.dispose();  // Clean up on component unmount
            clearInterval(intervalId);
        };
    }, [isFullScreen]);



    // 以下、フルスクリーン時のサイドバーの表示開始を少し遅らせる
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
        if (isFullScreen) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 500);
            return () => clearTimeout(timer);
        } else {
            setIsVisible(false);
        }
    }, [isFullScreen]);


    // const [isFullScreenActive, setIsFullScreenActive] = useState(false);
    // useEffect(()=>{
    //     const handleFullScreenChange = () => {
    //         const isFullscreen = !!document.fullscreenElement;
    //         setIsFullScreenActive(isFullscreen);
    //     };
    
    //     if (ref && ref.current) {
    //         ref.current.addEventListener('fullscreenchange', handleFullScreenChange);
    //     }
    
    //     return () => {
    //         if (ref && ref.current) {
    //             ref.current.removeEventListener('fullscreenchange', handleFullScreenChange);
    //         }
    //     };
    // }, [dataTheme, isFullScreenActive])





    return (
        <div ref={ref} className={embed ? "amcharts-embed-container" : "amcharts-container"} style={isFullScreen ? { width: '100vw', height: '100vh', position: "absolute", top: "0", left: "0" } : {}}>
            <div id="chartdiv" className={embed ? "amcharts-embed" : "amcharts-map"}></div>
            {isFullScreen && isVisible && (
                <div className='fullscreen-left-container' style={{position: "absolute", top: "10px", left: "10px"}}>
                    <div className='fullscreen-left-data'>
                        <div className='fullscreen-left-data-left'>
                            <div className='fullscreen-left-data-left-top'>20<span>/193</span></div>
                            <div className='fullscreen-left-data-left-bottom'>Countries</div>
                        </div>
                        <div className='fullscreen-left-data-right'>
                            <div className='fullscreen-left-data-right-top'>3<span>/7</span></div>
                            <div className='fullscreen-left-data-right-bottom'>Continents</div>
                        </div>
                    </div>
                    <CountryTable sortCountries={sortCountries} sortedCountries={sortedCountries} getFlagEmoji={getFlagEmoji} homeColors={homeColors} />
                </div>
            )}
        </div>

    );
})

export default CountryMap;