import {React, useState, useEffect, useRef} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import CityMap from '../CityMap';
import { getColorSet } from '../getColorSet';

import "../../stylesheets/Embed.css"

function EmbedCity() {

    const handle = useParams().handle

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const theme = queryParams.get('theme') ? queryParams.get('theme') : "light";

    const [homeUser, setHomeUser] = useState(null)
    const [dataTheme, setDataTheme] = useState(window.localStorage.getItem('bokenmap-darkmode-theme'))

    const cityMapRef = useRef(null)
    const [homeColors, setHomeColors] = useState(getColorSet("green"))


    useEffect(() => {
        // Add the 'embed-page' class to the body when the component mounts
        document.body.classList.add('embed-page');
        if(theme=="dark"){
            document.body.classList.add('embed-dark-body');
        }else{
            document.body.classList.add('embed-light-body');
        }
        
        // Cleanup: Remove the class when the component unmounts
        return () => {
            document.body.classList.remove('embed-page');
        };
    }, []);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${handle}`, {method: "GET", credentials: "include"})
        .then(response=>response.json())
        .then(data=>{
            
            if(data.exist){
                setHomeUser(data)
                setHomeColors(getColorSet(data.homeUser.plus === true  ? (data.homeUser.color || "green")  : "green"))
            }else{
            // ユーザーが存在しなかったらエラーページにゆく
            // window.location.href = "/error";
            setHomeUser(null)
          }
        }).catch(error => {console.error(error);});
    }, [handle])

    return (
        <div style={{width: "100vw", height: "100vh"}}>
            <Helmet>
                <title>Embed City | BokenMap</title>
            </Helmet>

            {/* {homeUser ? <CityMap dataTheme={theme} homeUser={homeUser} embed={true} style={{width: "100vw"}} /> : <></> } */}
            {homeUser && <CityMap dataTheme={theme} homeUser={homeUser} embed={true} isFullScreen={false} isEdit={false} ref={cityMapRef} homeColors={homeColors} style={{width: "100vw"}} />}



            <div className='embed-cats-container'>
                <div className='map-legend-each'>
                    <div className='legend-icon legend-lived-icon' style={{backgroundColor: homeColors.lived}}></div>
                    <div className='legend-text' style={{color: theme === "dark" ? "white" : "black"}}>Lived</div>
                </div>
                <div className='map-legend-each'>
                    <div className='legend-icon legend-visited-icon' style={{backgroundColor: homeColors.visited}}></div>
                    <div className='legend-text' style={{color: theme === "dark" ? "white" : "black"}}>Visited</div>
                </div>
                <div className='map-legend-each'>
                    <div className='legend-icon legend-transited-icon' style={{backgroundColor: homeColors.transited}}></div>
                    <div className='legend-text' style={{color: theme === "dark" ? "white" : "black"}}>Transited</div>
                </div>
            </div>


            {/* <div className='embed-logo-container' style={{bottom: "10px"}}>
                <div className='embed-logo-icon'></div>
                <div className='embed-logo-text' style={{ color: theme === "dark" ? "white" : "black" }}>BokenMap</div>
            </div> */}

            <div className='embed-logo-container' style={{backgroundColor: theme === "dark" ? "#171717" : "white", border: theme === "dark" ? "none" : "0.5px solid #bfbfbf"}} onClick={()=>{window.open("https://bokenmap.com", "_blank")}}>
                <div className='embed-logo-icon'></div>
                <div className='embed-logo-text' style={{ color: theme === "dark" ? "white" : "black" }}>BokenMap</div>
            </div>


        </div>
    );
}

export default EmbedCity;