import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Switch, FormControlLabel } from '@mui/material';
import toast, {Toaster} from "react-hot-toast"

import { useToast } from '../components/ToastContext';
import ThemeToggle from '../components/ThemeToggle';
import varColor from '../data/varColor';

function Settings({theme, setTheme, user, setUser}) {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const [checked, setChecked] = useState(!user.user.private);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteHandle, setDeleteHandle] = useState("");

    const handleChange = (event) => {
      setChecked(event.target.checked);

      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/settings/private`, {
            method: "POST",
            credentials: "include",
            headers: {"Content-Type": "application/json",},
            body: JSON.stringify({ isPrivate: !event.target.checked }), // Send true for private, false for public
        }).then((response) => {        
          if (response.ok) {
            toast.success(`Profile set to ${event.target.checked ? "Public" : "Private"}.`, {
                icon: event.target.checked ? "🌍" : "🔒",
                style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}
            })
          } else {
            toast.error("There was an error.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}})
          }
        }).catch((error) => console.error("Error:", error));
    };


    const handleDeleteAccount = () => {
        if(deleteHandle !== user.user.handle){
            setIsDeleting(false)
            toast.error("Incorrect handle.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}})
            return
        }

        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/delete`, {method: "POST",credentials: "include",  headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user }),
        })
        // .then(response => response.json())
        .then(data => {
            if (data.ok) {
                // window.location.href = `/`;
                setUser({
                    login: false,
                    user: null,
                    userColor: "green"
                  });
                navigate(`/`)
                showToast("Deleted Account. Sorry to see you go.", "success")
            } else {
                toast.error("There was an error.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}})
            }
        }).catch((error) => {
            console.error("ログアウト中にエラーが発生しました:", error);
        });
    };
    


    return (
        <div className='dashboard-right'>
            <Helmet>
                <title>Settings | BokenMap</title>
            </Helmet>

            <h1>Settings</h1>

            <div className='settings-each'>
                <h2>Profile Visibility</h2>
                <p style={{marginTop: "8px", fontSize: "14px"}} className='settings-text'>"Private" means that no one except you would be able to view your profile page. (They will however be able to view embedded maps.)</p>
                <div className='settings-each-content'>
                    <p className='settings-each-toggle-label' style={{marginRight: "13px"}}>Private</p>
                    {/* <FormControlLabel control={<Switch onChange={()=>{}} />}/> */}
                    <FormControlLabel control={<Switch checked={checked ? true : false} onChange={handleChange} color="default" sx={{
                        '& .MuiSwitch-thumb': { backgroundColor: checked ? '#0d780d' : "white" },
                        '& .MuiSwitch-track': { backgroundColor: checked ? '#4b824b' : "#9E9E9E" },
                    }} />} 
                    />
                    <p className='settings-each-toggle-label' style={{marginLeft: "-12px"}}>Public</p>
                </div>
            </div>
            <div className='settings-each'>
                <h2>Theme</h2>
                <p style={{marginTop: "8px", fontSize: "14px"}} className='settings-text'>This theme is set to the browser on your local device. Other viewers of your profile would most likely see it in light mode.</p>
                <div className='settings-each-content'>
                    <p className='settings-each-toggle-label' style={{marginRight: "13px"}}>Light</p>
                    <ThemeToggle setTheme={setTheme} className="theme-toggle"></ThemeToggle>
                    <p className='settings-each-toggle-label' style={{marginLeft: "-12px"}}>Dark</p>
                </div>
            </div>
            <div className='settings-line'></div>
            <div className='settings-each settings-delete-container'>
                <h3 className='delete-account-title'>Delete Account</h3>
                <p>This action cannot be undone.</p>
                {isDeleting && (
                    <div className='is-deleting-input-container'>
                        <p>Enter your handle to confirm your account deletion.</p>
                        <input className='delete-account-input' type='text' value={deleteHandle} onChange={(e) => setDeleteHandle(e.target.value)} placeholder='Enter your handle'></input>
                    </div>
                )}
                {isDeleting && (
                    <div className='is-deleting-button-container'>
                        <button className='delete-really-button' onClick={handleDeleteAccount}>Really Delete</button>
                        <div className='delete-cancel-button' onClick={()=>{setIsDeleting(false)}}>Cancel Deletion</div>
                    </div>
                )}
                {!isDeleting && <button className='delete-account-button' onClick={()=>{setIsDeleting(true)}}>Delete</button>}
            </div>

            <br></br>
            <br></br>
            {/* <p>Language</p> */}
        </div>
    );
}

export default Settings;