import { useState, useEffect } from "react";
import { Switch, FormControlLabel } from '@mui/material';



const ThemeToggle =({setTheme})=> {
    // darkmode用の切り替えスイッチ


    // darkmodeのflash防ぐ設定をしたら上じゃなくて下1行だけでよくなった。
    const [activeTheme, setActiveTheme] = useState(document.body.dataset.theme);

    const inactiveTheme = activeTheme === "light" ? "dark" : "light";


    useEffect(() => {
        document.body.dataset.theme = activeTheme;
        window.localStorage.setItem("bokenmap-darkmode-theme", activeTheme);
        setTheme(activeTheme)
    }, [activeTheme]);

    return(
        <FormControlLabel control={<Switch checked={activeTheme == "dark" ? true : false} onChange={()=>setActiveTheme(inactiveTheme)} color="default" sx={{
            '& .MuiSwitch-thumb': { backgroundColor: activeTheme == "dark" ? '#0d780d' : "white" },
            '& .MuiSwitch-track': { backgroundColor: activeTheme == "dark" ? '#4b824b' : "#9E9E9E" },
            '& .MuiSwitch-switchBase': { marginRight: "0px" }  // Remove right margin
          }} />} 
        //   label={activeTheme == "dark" ? "Dark" : "Light"}
        />
    )
}

export default ThemeToggle