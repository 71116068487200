import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import "../stylesheets/Pricing.css"

import varColor from "../data/varColor.json"
import EditCountryMap from './edit/EditCountryMap';
import { getColorSet } from '../components/getColorSet';
import { getFlagEmoji } from '../components/getFlagEmoji';
// import { sortCountries } from '../components/sortCountries';


function Pricing({user, setUser, handleModalOpen, theme}) {

    const [plus, setPlus] = useState(user.login ? user.user.plus : false)

    const plans = [
        {
            link: process.env.REACT_APP_STRIPE_PRICE_LINK,
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
            price: 3,
            duration: '/month'
        },
    ];






    useEffect(() => {
        if(user.login && user.user.stripeCustomerId !== ""){
            fetch(`${process.env.REACT_APP_BACK_DOMAIN}/billing/check`, {method: "POST", credentials: "include", headers: { "Content-Type": "application/json" },
                body: JSON.stringify({stripeCustomerId: user.user.stripeCustomerId})
            }).then(res => res.json())
            .then(data => {
            //   console.log(data.data);
              
              if(data.data.length === 0){
                fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
                  body: JSON.stringify({handle: user.user.handle}),
                }).then((response) => {
                    if (!response.ok) {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response;
                }).then((result) => {
                    setUser({...user, user: {...user.user, plus: false}})
                    setPlus(false)
                }).catch((err) => console.error(err));
              }else{
                const isActive = data.data[0].status === "active" ? true : false
                if(user.user.plus === isActive){
                  return setPlus(isActive)
                }else{
                  fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
                    body: JSON.stringify({handle: user.user.handle}),
                  }).then((response) => {
                      if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                      }
                      return response;
                    }).then((result) => {
                      setUser({...user, user: {...user.user, plus: isActive}})
                      setPlus(isActive)
                    }).catch((err) => console.error(err));
                }
              }
            }).catch(err => console.log(err));
        }else{
            // 未ログインまたはStripeIdなし
            setPlus(false)
        }
    }, []);






    const clickSubscribe = async () => {
        if (user.user) {
            if(!user.user.email){
                return toast("Please login to subscribe.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}, duration: 1000})
            }
            // console.log(user.user.email, plans[0].priceId); 
            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/billing/create-checkout-session`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: user.user.email,
                        priceId: plans[0].priceId, // Replace with your Stripe price ID
                    }),
                });
    
                const { id, url } = await response.json();
    
                // Redirect to Stripe Checkout
                // window.location.href = `https://checkout.stripe.com/pay/${id}`;
                window.location.href = url;
                // window.open(url);
            } catch (error) {
                console.error('Error creating checkout session:', error);
            }
        } else {
            // alert('Please login to subscribe.');
            toast("Please login to subscribe.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? varColor.darkText : varColor.lightText}, duration: 1000})
            handleModalOpen()
        }
    };



    const goToPortal = () => {
        window.open(`${process.env.REACT_APP_STRIPE_PORTAL_LINK}?prefilled_email=${user.user ? user.user.email : null}`, "_blank");
    }






    const [mapColor, setMapColor] = useState("green")

    const selectMapColor = (color) => {
        setMapColor(color)
    }

    const userColors = getColorSet(mapColor)

    const countryArr = [
        {
          "shortName": "SG",
          "name": "Singapore",
          "continent": "Asia",
          "status": "1"
        },
        {
            "shortName": "JP",
            "name": "Japan",
            "continent": "Asia",
            "status": "1"
        },
        {
          "shortName": "MY",
          "name": "Malaysia",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "ES",
          "name": "Spain",
          "continent": "Europe",
          "status": "2"
        },
        {
            "shortName": "LK",
            "name": "Sri Lanka",
            "continent": "Asia",
            "status": "3"
          },
        {
          "shortName": "FR",
          "name": "France",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "PT",
          "name": "Portugal",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "TH",
          "name": "Thailand",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "VN",
          "name": "Vietnam",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "ID",
          "name": "Indonesia",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "AU",
          "name": "Australia",
          "continent": "Oceania",
          "status": "2"
        },
        {
            "shortName": "US",
            "name": "United States",
            "continent": "North America",
            "status": "1"
          },
        {
          "shortName": "TW",
          "name": "Taiwan",
          "continent": "Asia",
          "status": "3"
        },
        {
          "shortName": "CR",
          "name": "Costa Rica",
          "continent": "North America",
          "status": "2"
        },
        {
          "shortName": "GB",
          "name": "United Kingdom",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "CH",
          "name": "Switzerland",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "IT",
          "name": "Italy",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "AT",
          "name": "Austria",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "DE",
          "name": "Germany",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "LU",
          "name": "Luxembourg",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "BE",
          "name": "Belgium",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "QA",
          "name": "Qatar",
          "continent": "Asia",
          "status": "2"
        },
        {
          "shortName": "NL",
          "name": "Netherlands",
          "continent": "Europe",
          "status": "2"
        },
        {
          "shortName": "CA",
          "name": "Canada",
          "continent": "North America",
          "status": "2"
        }
      ]




    return (
        <div className='page'>
            <Helmet>
                <title>Pricing | BokenMap</title>
            </Helmet>
            <h1>Pricing</h1>
            <p>Upgrade to change the map/ui color from the default green.</p>

            <div className='pricing-container'>
                <div className='pricing-each'>
                    <div className='price-title'>Free</div>
                    <div className='price-text'><span className='price-span'>$0</span>/month</div>
                    {!user.login && (<div onClick={handleModalOpen} className='pricing-green-button' style={{backgroundColor: userColors.ui}}>Get Started</div>)}
                    {user.login && !plus && <div className='pricing-gray-button'>Current Plan</div>}
                    {user.login && plus && <div className='pricing-gray-button'>You are on Plus</div>}
                    <div className='feature-list'>
                        <div className='feature-list-item'>
                            <FontAwesomeIcon icon={faCheck} className='feature-list-icon' style={{color: userColors.ui}} />
                            <span className='feature-list-text'>Map Countries</span>
                        </div>
                        <div className='feature-list-item'>
                            <FontAwesomeIcon icon={faCheck} className='feature-list-icon' style={{color: userColors.ui}} />
                            <span className='feature-list-text'>Map Cities</span>
                        </div>
                        <div className='feature-list-item'>
                            <FontAwesomeIcon icon={faCheck} className='feature-list-icon' style={{color: userColors.ui}} />
                            <span className='feature-list-text'>Download/Embed Maps</span>
                        </div>
                    </div>
                </div>
                <div className='pricing-each'>
                    <div className='price-title'>Plus</div>
                    <div className='price-text'><span className='price-span'>$3</span>/month</div>
                    {!user.login && (<div onClick={clickSubscribe} className='pricing-green-button' style={{backgroundColor: userColors.ui}}>Get Started</div>)}
                    {user.login && !plus && <div onClick={clickSubscribe} className='pricing-green-button' style={{backgroundColor: userColors.ui}}>Get Plus</div>}
                    {user.login && plus && <div onClick={goToPortal} className='pricing-white-button'>Manage Subscription</div>}
                    <div className='feature-list'>
                        <div className='feature-list-item'>
                            <FontAwesomeIcon icon={faCheck} className='feature-list-icon' style={{color: userColors.ui}} />
                            <span className='feature-list-text'>Everything in Free</span>
                        </div>
                        <div className='feature-list-item'>
                            <FontAwesomeIcon icon={faCheck} className='feature-list-icon' style={{color: userColors.ui}} />
                            <span className='feature-list-text'>Color Options</span>
                        </div>
                    </div>
                </div>
            </div>


            <div className='pricing-links-container'>
                <Link to="/terms" className="pricing-terms-link pricing-link-each">Terms of Service</Link>
                <Link to="/privacy" className="pricing-privacy-link pricing-link-each">Privacy Policy</Link>
                <Link to="/commerce" className="pricing-commerce-link pricing-link-each">Commercial Disclosure</Link>
            </div>


            <h1 style={{marginTop: "100px", textAlign: "center"}}>What you can do with <span className='plus-span' style={{color: userColors.ui}}>Plus</span></h1>
            <p style={{fontSize: "15px", textAlign: "center"}}>The color theme will be applied to the map and UI. Visitors of your profile will be graced with your color theme.</p>

            <div style={{display: "flex", marginTop: "35px"}} className='pricing-color-option-container'>
                <div className='color-option-container'>
                    <div className='color-option option-green' onClick={()=>selectMapColor("green")}></div>
                    {mapColor === "green" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                </div>
                <div className='color-option-container'>
                    <div className='color-option option-blue' onClick={()=>selectMapColor("blue")}></div>
                    {mapColor === "blue" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                </div>
                <div className='color-option-container'>
                    <div className='color-option option-red' onClick={()=>selectMapColor("red")}></div>
                    {mapColor === "red" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                </div>
                <div className='color-option-container'>
                    <div className='color-option option-purple' onClick={()=>selectMapColor("purple")}></div>
                    {mapColor === "purple" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                </div>
                <div className='color-option-container'>
                    <div className='color-option option-brown' onClick={()=>selectMapColor("brown")}></div>
                    {mapColor === "brown" && <FontAwesomeIcon icon={faCircleCheck} className='color-option-check' />}
                </div>
            </div>
            <div className='plus-color-demo-container'>
                <div className='plus-map-container'>
                    <EditCountryMap userColors={userColors} dataTheme={theme} countryArr={countryArr} />
                </div>
                <div className='plus-ui-container'>
                    <table className='home-country-table'>
                        <thead>
                            <tr>
                                <th className='country-th-status'>
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        {/* <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img> */}
                                        <div style={{marginLeft: "5px"}}>Status</div></div>
                                </th>
                                <th className='country-th-country' style={{ width: "210px" }}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {/* <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img> */}
                                        <div style={{marginLeft: "5px"}}>Country</div></div>
                                </th>
                                <th className='country-th-continent' style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "90px"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {/* <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img> */}
                                    <div style={{marginLeft: "5px"}}>Continent</div></div> 
                                </th>
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                        {countryArr.slice(0, 5).map((country, index) => (
                        <tr key={index}>
                            <td className='country-td-status'>
                                {country.status == "1" && (<div className='status-one status-icon' style={{backgroundColor: userColors.lived}}></div>)}
                                {country.status == "2" && (<div className='status-two status-icon' style={{backgroundColor: userColors.visited}}></div>)}
                                {country.status == "3" && (<div className='status-three status-icon' style={{backgroundColor: userColors.transited}}></div>)}
                            </td>
                            <td className='country-td-country'><span className='flag-emoji'>{getFlagEmoji(country.shortName)}</span>{country.name}</td>
                            <td className='country-td-continent'>{country.continent}</td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}



export default Pricing;