import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Select from "react-select"
import AsyncSelect from 'react-select/async';
import toast, {Toaster} from "react-hot-toast"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPen, faTrash, faCheck, faSort, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons';

import { getColorSet } from '../../components/getColorSet';
import varColor from '../../data/varColor';
import { getFlagEmoji } from '../../components/getFlagEmoji';
import EditCountryMap from './EditCountryMap';
import CityMap from '../../components/CityMap';
import CountryData from "../../data/country.json"

import "../../stylesheets/Edit.css"




function EditCity({user, dataTheme, mapColor}) {
    const navigate = useNavigate()

    const userColors = getColorSet(mapColor)
    const [cityIsFullScreen, setCityIsFullScreen] = useState(false);
    const cityMapRef = useRef(null)

    const handle = user.user.handle
    const [homeUser, setHomeUser] = useState(null)
    const [cityArr, setCityArr] = useState([])
    const [originalCityArr, setOriginalCityArr] = useState([]) //originalCountryArrは、データベースから貰ったもの

    useEffect(() => {
        document.body.classList.add('edit-page');
        
        return () => {
            document.body.classList.remove('edit-page');
        };
    }, []);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${handle}`, {method: "GET", credentials: "include"})
        .then(response=>response.json())
        .then(data=>{
          if(data.exist){       
            setHomeUser(data)
            setCityArr(data.cities)
            setOriginalCityArr(data.cities) 
          }else{
            setHomeUser(null)
          }
        }).catch(error => {console.error(error);});
    }, [])



    const [selectedCity, setSelectedCity] = useState("");
    const [selectedCat, setSelectedCat] = useState("visited");


    const fetchCities = async (inputValue) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/api/search/city?name=${inputValue}`);
          const data = await response.json();
          
          return data.geonames.map((city) => ({ value: city.geonameId, label: `${city.name}, ${city.countryName}`, cityName: city.name, countryName: city.countryName, countryCode: city.countryCode, lat: city.lat, lng: city.lng }));
        } catch (error) {
          console.error('Error fetching cities:', error);
          return [{value: "lived", label: "Lived"},];
        }
     };


    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };


    const catOptions = [
        {value: "lived", label: "Lived"},
        {value: "visited", label: "Visited"},
        {value: "transited", label: "Transited"},
    ]
    const handleCatChange = (option) => {
        const selectedCat = option?.value;
        setSelectedCat(selectedCat);
    };


    const handleAdd = async () => {
        if(selectedCity && selectedCat){
            let statusNum
            if(selectedCat === "lived"){
                statusNum = "1"
            }else if(selectedCat === "visited"){
                statusNum = "2"
            }else if(selectedCat === "transited"){
                statusNum = "3"
            }
            const newCity = {
                city: selectedCity.cityName,
                continent: CountryData[selectedCity.countryCode].continent_code,
                country: selectedCity.countryCode,
                geoname: selectedCity.value,
                lat: selectedCity.lat,
                long: selectedCity.lng,
                status: statusNum,
            }
            const existingCityIndex = cityArr.findIndex(city => city.geoname === selectedCity.value);
            if (existingCityIndex !== -1) {
                // Update the existing country
                const updatedCities = [...cityArr];
                updatedCities[existingCityIndex] = newCity;
                setCityArr(updatedCities)
            } else {
                // Add new country
                setCityArr([...cityArr, newCity]);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/edit/city`, {
                    method: "POST",
                    credentials: "include",
                    headers: {"Content-Type": "application/json",},
                    body: JSON.stringify({
                        city: selectedCity.cityName,
                        continent: CountryData[selectedCity.countryCode].continent_code,
                        country: selectedCity.countryCode,
                        geoname: selectedCity.value,
                        lat: selectedCity.lat,
                        long: selectedCity.lng,
                        status: statusNum,
                    }),
                });
                if (!response.ok) {
                    const error = await response.json();
                    console.error("Error adding/updating country:", error);
                }
            } catch (error) {
                console.error("Error adding/updating country:", error);
            }
        }else{
            toast.error("Please select a city.", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}, duration: 1000})
        }
    }







    const [numberCount, setNumberCount] = useState({"lived": 0, "visited": 0, "transited": 0,})

    useEffect(() => {
        const newCount = {"lived": 0, "visited": 0, "transited": 0,};
        cityArr.forEach(city => {
            newCount.lived += city.status === "1" ? 1 : 0;
            newCount.visited += city.status === "2" ? 1 : 0;
            newCount.transited += city.status === "3" ? 1 : 0;
        });
        setNumberCount(newCount);
    }, [cityArr])
    


    const [isEditing, setIsEditing] = useState(false);

    const toggleEditMode = () => {
      setIsEditing((prev) => !prev);
    };

    const handleDelete = async (cityGeoname) => {
        setCityArr(cityArr.filter(city => city.geoname !== cityGeoname))
        try {
            const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/edit/city/delete`, {
              method: "POST",
              credentials: "include",
              headers: {"Content-Type": "application/json",},
              body: JSON.stringify({ cityGeoname: cityGeoname }),
            });
      
            if (response.ok) {
            } else {
              const error = await response.json();
            }
          } catch (error) {
            console.error("Error deleting country:", error);
            toast.error("Failed to delete country.", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}});
          }
    }









    const varColor = {
        "darkGray": "#303030",
        "darkBack": "#181818",
        "lightGray": "#F9FAFB"
    };

    const countrySelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkBack : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "5px",
            boxShadow: 'none',
            width: "170px",
            height: "30px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28px',
            // padding: '0 6px'
            paddingLeft: "10px",
            paddingTop: "2px",
            fontSize: "14px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: dataTheme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 0px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkBack : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "13px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkBack : '#fff', // Background color of the dropdown
            width: "170px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
        input: (provided) => ({
            ...provided,
            color: (dataTheme === "dark" ? "white" : "#1E1E1E"), // Change this to your desired color
        }),
    }




    const catSelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkBack : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "5px",
            boxShadow: 'none',
            width: "100px",
            height: "30px",
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            // padding: '0 6px'
            paddingLeft: "0px",
            paddingTop: "0px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: dataTheme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 10px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkBack : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkBack : '#fff', // Background color of the dropdown
            width: "100px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
    }







    const tableCatSelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkGray : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "4px",
            boxShadow: 'none',
            width: "37px",
            height: "24px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "19px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkBack : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
            // marginTop: "2px",
            // marginBottom: "2px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkGray : '#fff', // Background color of the dropdown
            // width: "40px",
            paddingRight: "2px",
            marginTop: "4px",
            border: `1px solid ${dataTheme === "dark" ? "#424242" : varColor.lightGray}`,
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // height: '20px',
            padding: "0px",
            margin: "auto",
            // marginRight: "1px"
            marginLeft: "1px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "14px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto",
            fontSize: "4px",

        }),
    }




    const tableCatOptions = [
        { value: 'lived', color: userColors.lived },
        { value: 'visited', color: userColors.visited },
        { value: 'transited', color: userColors.transited }
    ];

    const tableCatOptionUi = (props) => {
        const { data, innerRef, innerProps } = props;
      
        return (
          <div ref={innerRef} {...innerProps} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: data.color,
                marginTop: "2px",
                marginBottom: "2px",
                marginLeft: "2px",
              }}
            ></div>
          </div>
        );
    };

    const CustomSingleValue = ({ data }) => {  
        // console.log("CustomSingleValue data:", data);
        return (
          <div>
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: data.color,
              }}
            ></div>
          </div>
        );
    };

    const handleTableCatChange = async (option, cityGeoname) => {
        const updatedCities = [...cityArr];
        const cityIndex = updatedCities.findIndex(city => city.geoname === cityGeoname);
        if (cityIndex !== -1) {
            updatedCities[cityIndex].status = option.value === 'lived' ? "1" : option.value === 'visited' ? "2" : "3";
            setCityArr(updatedCities);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/edit/city`, {
                    method: "POST",
                    credentials: "include",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        geoname: cityGeoname,
                        status: option.value === 'lived' ? "1" : option.value === 'visited' ? "2" : "3",
                    }),
                });
                if (!response.ok) {
                    const error = await response.json();
                    console.error("Error updating country:", error);
                    toast.error("Failed to update country.", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}});
                }
            } catch (error) {
                console.error("Error updating country:", error);
                toast.error("Failed to update country.", {style: {backgroundColor: dataTheme === "dark" ? varColor.darkGray : varColor.lightGray, color: dataTheme === "dark" ? "#e7e7e7" : varColor.lightText}});
            }
        }
    };

    const [searchQuery, setSearchQuery] = useState("")

    const filteredCities = cityArr.filter(city => city.city.toLowerCase().includes(searchQuery.toLowerCase()))



    const sortMenuRef = useRef(null);
    const sortIconRef = useRef(null);
    const [sortMenu, setSortMenu] = useState(false);

    const handleSortChange = (option) => {
        const sortedCities = [...cityArr].sort((a, b) => {
            if (option === 'city-a') {
                return b.city.localeCompare(a.city);
            } else if (option === 'city-z') {
                return a.city.localeCompare(b.city);
            } else if (option === 'country-a') {
                return b.country.localeCompare(a.country);
            } else if (option === 'country-z') {
                return a.country.localeCompare(b.country);
            }
            return 0;
        });
        setCityArr(sortedCities);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortMenuRef.current && !sortMenuRef.current.contains(event.target) && sortIconRef.current && !sortIconRef.current.contains(event.target)) {
                setSortMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className='edit-page'>
            <Helmet>
                <title>Edit City | BokenMap</title>
            </Helmet>

            <aside className='edit-sidebar'>
                <div className="nav-left" style={{marginTop: "25px", marginLeft: "22px"}}>
                    <div className="nav-left-icon" onClick={() => navigate(`/`)}></div>
                    <h1 className="nav-left-name" onClick={() => navigate(`/`)}>BokenMap</h1>
                </div>
                <div className='edit-sidebar-handle' onClick={() => navigate(`/${handle}`)}><FontAwesomeIcon icon={faArrowLeft} className='sidebar-back-arrow' /><div className='sidebar-handle-text'>{handle}</div></div>
                <div className="edit-sidebar-row">
                    <input type="text" className='search-input' placeholder='Search your list' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    {/* <Select options={sortOptions} className='sort-select' onChange={handleSortChange} styles={sortSelectStyle} isSearchable={false} placeholder="Sort"// value={sortOptions.find(option => option.value === selectedSort)} /> */}
                    <div className='sidebar-sort'>
                        <div className='sort-icon-container'>
                            <FontAwesomeIcon icon={faArrowDownWideShort} className='sort-icon' onClick={()=>{setSortMenu(!sortMenu)}} ref={sortIconRef} />
                        </div>
                        {sortMenu && (
                            <div className='sort-menu' ref={sortMenuRef}>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("city-a")}}>City A-Z</div>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("city-z")}}>City Z-A</div>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("country-a")}}>Country A-Z</div>
                                <div className='sort-option' onClick={()=>{setSortMenu(false); handleSortChange("country-z")}}>Country Z-A</div>
                            </div>
                        )}
                    </div>
                    {isEditing ? (
                        <div className='check-container' onClick={toggleEditMode} data-tooltip-content="Edit Countries" data-tooltip-id="edit-sidebar-button">
                            <FontAwesomeIcon icon={faCheck} className='edit-sidebar-icon check-icon' />
                        </div>
                    ) : (
                        <div className='edit-sidebar-button' onClick={toggleEditMode} data-tooltip-content="Edit Countries" data-tooltip-id="edit-sidebar-button">
                            <FontAwesomeIcon icon={faPen} className='edit-sidebar-icon' />
                        </div>
                    )}
                </div>
                <div className='country-table'>
                    {homeUser ? filteredCities.slice().reverse().map((city, index) => {
                        return (
                            <div key={index} className='country-row'>
                                <div className="country-cell-left">
                                    <div className='country-cell-cat'>
                                        {isEditing ?
                                            <Select 
                                                options={tableCatOptions} 
                                                components={{ Option: tableCatOptionUi, SingleValue: CustomSingleValue }} 
                                                value={tableCatOptions.find(option => option.value === (city.status === "1" ? "lived" : city.status === "2" ? "visited" : "transited"))}
                                                onChange={(option) => handleTableCatChange(option, city.geoname)} 
                                                styles={tableCatSelectStyle} 
                                                isSearchable={false} 
                                            /> :
                                            <div className={`cat-icon-${city.status == "1" ? "lived" : city.status == "2" ? "visited" : "transited"}`} style={{backgroundColor: userColors[city.status == "1" ? "lived" : city.status == "2" ? "visited" : "transited"]}}></div>
                                        }
                                    </div>
                                    <div className='country-cell-name'>{getFlagEmoji(city.country)} {city.city}</div>
                                </div>
                                <div className='country-cell-func'>
                                    <div className='country-each-delete'>
                                        {isEditing && (<FontAwesomeIcon icon={faTrash} className='country-each-delete-icon' onClick={()=>{handleDelete(city.geoname)}} />)}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <></>}
                    {filteredCities.length === 0 && (
                        <div className='no-country-text-container'>
                            <div className='no-country-text'>No cities</div>
                        </div>
                    )}
                </div>
            </aside>
            <div className='edit-content'>
                <div className='add-row-container'>
                    {/* <input type="text" /> */}
                    <h3>Select a new city, and add them to your list!</h3>
                    <p className='edit-info-text'>If a city is already in your list, it will be updated.</p>
                    <div className="add-row">
                        <AsyncSelect 
                        loadOptions={fetchCities}
                        className='country-select'
                         styles={countrySelectStyle} 
                         isSearchable={true} 
                         onChange={handleCityChange}
                         placeholder="Search City" />
                        <Select options={catOptions} className='cat-select' value={catOptions.find(option => option.value === selectedCat)} onChange={handleCatChange} styles={catSelectStyle} isSearchable={false} />
                        <div className='country-add-button' onClick={handleAdd}>Add</div>
                    </div>
                </div>
                <div className='map-container'>
                    {/* <EditCountryMap dataTheme={dataTheme} countryArr={cityArr} /> */}
                    {homeUser && <CityMap dataTheme={dataTheme} homeUser={homeUser} embed={false} isFullScreen={cityIsFullScreen} isEdit={true} ref={cityMapRef} homeColors={userColors} />}
                </div>
                <div className='edit-data-container'>
                    <div className="edit-data-each">
                        <div className="edit-data-top">{numberCount.lived}</div>
                        <div className="edit-data-bottom">
                            <div className="edit-data-icon-lived" style={{backgroundColor: userColors.lived}}></div>
                            <div className="edit-data-text">Lived</div>
                        </div>
                    </div>
                    <div className="edit-data-each">
                        <div className="edit-data-top">{numberCount.visited}</div>
                        <div className="edit-data-bottom">
                            <div className="edit-data-icon-visited" style={{backgroundColor: userColors.visited}}></div>
                            <div className="edit-data-text">Visited</div>
                        </div>
                    </div>
                    <div className="edit-data-each">
                        <div className="edit-data-top">{numberCount.transited}</div>
                        <div className="edit-data-bottom">
                            <div className="edit-data-icon-transited" style={{backgroundColor: userColors.transited}}></div>
                            <div className="edit-data-text">Transited</div>
                        </div>
                    </div>
                </div>
                <div className='edit-header'>
                    {/* <h2>Edit Country<span onClick={()=>{navigate(`/${handle}`)}}>@{handle}</span></h2> */}
                    <h2>Edit City</h2>
                    <div className='edit-other' onClick={()=>{navigate(`/edit/country`)}}>Edit countries instead</div>
                </div>
            </div>

        </div>
    );
}

export default EditCity;