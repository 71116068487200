import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

export default function CityPieChart({homeUser, mapColor}) {




    const colorShades = {
        "green": [
          "#005703",
          "#047908",
          "#07bd0e",
          "#09ee12",
          "#75fa62",
          "#a6ff9a"
        ],
        "blue": [
          "#093788",
          "#1653b8",
          "#3069e4",
          "#5590ff",
          "#7fb5ff",
          "#b9e0ff"
        ],
        "red": [
          "#a20606",
          "#ca1d1d",
          "#f14a4a",
          "#fc7e7e",
          "#ffafaf",
          "#ffd8d8"
        ],
        "purple": [
          "#71025d",
          "#871174",
          "#c632c1",
          "#e068df",
          "#f49dff",
          "#fac9ff"
        ],
        "brown": [
          "#642f00",
          "#7d3e06",
          "#b86318",
          "#db8644",
          "#ffbd6d",
          "#ffe1b3"
        ]
      }      





    
    let countPerContinent = {
        "AS": 0,
        "EU": 0,
        "AF": 0,
        "NA": 0,
        "SA": 0,
        "OC": 0,
    }
    homeUser.cities.forEach(city=>{
        countPerContinent[city.continent]++
    })



    // Reference to the canvas element
    const chartRef = useRef(null);

    useEffect(() => {
        // Dummy data for the chart
        const continentNameMap = {
            "AS": "Asia",
            "EU": "Europe",
            "AF": "Africa",
            "NA": "North America",
            "SA": "South America",
            "OC": "Oceania"
        };

        const sortedContinentData = Object.entries(countPerContinent)
            .sort((a, b) => b[1] - a[1])
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        const labels = Object.keys(sortedContinentData).map(
            (continent, index) => `${Object.values(sortedContinentData)[index]} ${continentNameMap[continent]}`
        );

        const data = Object.values(sortedContinentData);

        // Create the chart
        const ctx = chartRef.current.getContext('2d');

        new Chart(ctx, {
            type: 'pie',
            data: {
                labels,
                datasets: [
                    {
                        data,
                        backgroundColor: colorShades[mapColor],
                    },
                ],
            },
            options: {
                borderWidth: 0.2,
                borderColor: "gray",
                backgroundColor: "black",
                plugins: {
                    // title: {
                    //     display: false,
                    //     text: 'Cities',
                    //     fontSize: 18,
                    //     padding: 20,
                    //     fontColor: "#999",
                    //     fontStyle: 'Normal',
                    //     fontFamily: "Montserrat",
                    //     fullWidth: true
                    // },
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            boxWidth: 24,
                            fontColor: 'red', // Adjust legend font color
                            fontSize: 20 // Adjust legend font size
                        }
                    },
                    outlabels: {
                        text: '%l %p',
                        color: 'black',
                        stretch: 45,
                        font: {
                            resizable: false,
                            minSize: 12,
                            maxSize: 15
                        }
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });

        // Cleanup chart instance on component unmount
        return () => {
            if (chartRef.current) {
                Chart.getChart(chartRef.current)?.destroy();
            }
        };
    }, []);

    return (
        <div className='city-chart-container'>
            <canvas ref={chartRef} className='city-chart-canvas' style={{height: "140px", width: "160px"}}></canvas>
        </div>
    );
}
