import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faPen, faCode, faImage, faExpand, faCircleInfo, faCopyright, faArrowUpFromBracket, faLeaf } from '@fortawesome/free-solid-svg-icons';


import { Tooltip } from 'react-tooltip'
import Select from "react-select"
import toast, { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';

import { toPng } from 'html-to-image';
import html2canvas from 'html2canvas';

import "../stylesheets/HomeProfile.css"

import CountryMap from './CountryMap';
import CityMap from './CityMap';

import countryData from "../data/country.json"
import unCountryData from "../data/unCountry.json"

import { getColorSet } from '../components/getColorSet';
import varColor from '../data/varColor';
import { getFlagEmoji } from '../components/getFlagEmoji';
import CityPieChart from "./CityPieChart";


function HomeProfile({user, homeUser, queryTab, mapColor}) {
    const navigate = useNavigate()
    
    const homeColors = getColorSet(homeUser.homeUser.plus === true  ? (homeUser.homeUser.color || "green")  : "green")


    const countryMapRef = useRef(null);
    const cityMapRef = useRef(null)

    const [activeTab, setActiveTab] = useState(queryTab || "country");
    const switchTab = (tab) => {
        setActiveTab(tab)
    }

    const [dataTheme, setDataTheme] = useState(window.localStorage.getItem('bokenmap-darkmode-theme'))
    // setDataTheme(window.localStorage.getItem('bokenmap-darkmode-theme'))


    // テーブルをソートする関係
    const [sortedCountries, setSortedCountries] = useState(homeUser.countries);
    const [sortedCities, setSortedCities] = useState(homeUser.cities);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const sortCountries = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedArray = [...sortedCountries].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedCountries(sortedArray);
        setSortConfig({ key, direction });
    };
    const sortCities = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedArray = [...sortedCities].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedCities(sortedArray);
        setSortConfig({ key, direction });
    };




    // Statusが1と2の国のjson
    const visitedCountries = homeUser.countries.filter(country => country.status !== "3");
    // Statusが1と2の国で、国連加盟国の国のjson
    const visitedUnCountries = visitedCountries.filter(country => unCountryData.hasOwnProperty(country.shortName));
    // 行った国連加盟国で、大陸の種類　["Asia", "North America"]みたいな形で出てくる
    const visitedUnContinents = [...new Set(visitedUnCountries.map(country => country.continent))];

    const countryInContinent = visitedUnCountries.reduce((acc, country) => {
        const continent = country.continent;
        // 各大陸に国があるか確認し、なければ初期値を設定
        if (!acc[continent]) {acc[continent] = 0;}
        // 国の数をインクリメント
        acc[continent]++;
        return acc;
      }, {});


    // Statusが1と2の街のjson
    const visitedCities = homeUser.cities.filter(city => city.status !== "3");
    // 行った街で、大陸の種類　["Asia", "North America"]みたいな形で出てくる
    const visitedCityContinents = [...new Set(visitedCities.map(city => city.continent))];



    function copyEmbedCode(type, handle, theme) {
        var embedLink = `<iframe src="https://bokenmap.com/embed/${type}/${handle}?theme=${theme}" frameborder="0" width="500px" height="250px"></iframe>`
        navigator.clipboard.writeText(embedLink).then(function() {
            // alert("Embed code copied to clipboard!")        
            toast.success("Embed code copied to clipboard!", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? "#e7e7e7" : varColor.lightText}, duration: 1200})
        }, function(err) {
            console.error("Unable to copy embed code: ", err)
            toast.error("Failed to copy embed code.", {style: {backgroundColor: theme === "dark" ? varColor.darkGray : varColor.lightGray, color: theme === "dark" ? "#e7e7e7" : varColor.lightText}})
        });
    }



    async function downloadCountryMapy(dataTheme) {
        
        const chartDiv = countryMapRef.current;
        
        if (chartDiv) {
            const downloadPromise = new Promise(async (resolve, reject) => {
                try {
                    const dataUrl = await toPng(chartDiv);

                    const img = new Image();
                    img.src = dataUrl;
    
                    img.onload = async () => {
                        const scaleFactor = 2;
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext("2d");
    
                        canvas.width = chartDiv.clientWidth * scaleFactor;
                        canvas.height = chartDiv.clientHeight * scaleFactor;
    
                        ctx.scale(scaleFactor, scaleFactor);
                        ctx.drawImage(img, 0, 0, chartDiv.clientWidth, chartDiv.clientHeight);


                        ctx.font = '500 12px sans-serif';
                        ctx.fillStyle = dataTheme === "dark" ? 'rgb(255, 255, 255)' : "rgb(0, 0, 0)"; // White color with transparency
                        ctx.textAlign = 'center';
                        ctx.fillText('BokenMap', chartDiv.clientWidth / 2 + 15, chartDiv.clientHeight - 15);
            
                        // Load and draw green circle icon
                        const icon = new Image();
                        icon.src = 'green-icon.png'; // Set your icon path here
            
                        // Wait for the icon image to load
                        await new Promise((resolve) => {
                            icon.onload = resolve;
                        });


                        const iconSize = 7;
                        ctx.drawImage(icon, (chartDiv.clientWidth / 2) - 22, chartDiv.clientHeight - 22, iconSize, iconSize);
    
                        const finalDataUrl = canvas.toDataURL('image/png');
                        const link = document.createElement('a');
                        link.download = `${homeUser.homeUser.username}'s-country-map.png`;
                        link.href = finalDataUrl;
                        link.click();
    
                        resolve();
                    };
    
                    img.onerror = () => {
                        reject(new Error('Failed to load image'));
                    };
                } catch (error) {
                    reject(error);
                }
            });
    
            toast.promise(downloadPromise, {
                loading: "Downloading image...",
                success: "Save image to device.",
                error: "Failed to save."
            });
        }
    }


    async function downloadCountryMap(dataTheme) {
        const chartDiv = countryMapRef.current;
    
        if (chartDiv) {
            const downloadPromise = new Promise(async (resolve, reject) => {
                try {
                    // 要素サイズの正確な取得
                    const { width, height } = chartDiv.getBoundingClientRect();
    
                    // HTML要素をキャンバスに変換
                    const canvas = document.createElement('canvas');
                    const scaleFactor = 2; // 解像度を高める
                    canvas.width = width * scaleFactor;
                    canvas.height = height * scaleFactor;
                    const ctx = canvas.getContext("2d");
                    ctx.scale(scaleFactor, scaleFactor);
    
                    const chartCanvas = await html2canvas(chartDiv, {
                        scale: scaleFactor,
                        useCORS: true,
                        backgroundColor: null,
                        width,
                        height
                    });
                    ctx.drawImage(chartCanvas, 0, 0, width, height);
    
                    // ロゴテキストの追加
                    const textX = width / 2; // テキスト中央位置
                    const textY = height - 4; // テキストY位置
                    ctx.font = '500 12px sans-serif';
                    ctx.fillStyle = dataTheme === "dark" ? 'rgb(255, 255, 255)' : "rgb(0, 0, 0)";
                    ctx.textAlign = 'center';
                    ctx.fillText('BokenMap', textX, textY);
    
                    // ロゴアイコンの追加
                    const icon = new Image();
                    icon.src = 'green-icon.png'; // ロゴアイコンのパスを指定
    
                    await new Promise((resolve) => {
                        icon.onload = resolve;
                    });
    
                    const iconSize = 7; // ロゴアイコンのサイズ
                    const iconX = textX - 37;
                    const iconY = textY - 7; // テキストの右上に配置
                    ctx.drawImage(icon, iconX, iconY, iconSize, iconSize);
    
                    // 画像としてダウンロード
                    const finalDataUrl = canvas.toDataURL('image/png');
                    const link = document.createElement('a');
                    link.download = `${homeUser.homeUser.username}'s-country-map.png`;
                    link.href = finalDataUrl;
    
                    // Safari対応：一時的にDOMに追加してダウンロード
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
    
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
    
            toast.promise(downloadPromise, {
                loading: "Downloading image...",
                success: "Save image to device.",
                error: "Failed to save."
            });
        }
    }
    





    // async function downloadCityMap(dataTheme) {
    // }
    const handleCityMapDownload = () => {
        cityMapRef.current?.handleDownload();
    };






    const [countryIsFullScreen, setCountryIsFullScreen] = useState(false);
    const [cityIsFullScreen, setCityIsFullScreen] = useState(false);

    // const handleFullScreen = (mapType) => {
    //     const mapRef = mapType === "country" ? countryMapRef : cityMapRef
    //     if (mapRef.current) {
    //         if (mapRef.current.requestFullscreen) {
    //             mapRef.current.requestFullscreen();
    //         } else if (mapRef.current.mozRequestFullScreen) { // Firefox
    //             mapRef.current.mozRequestFullScreen();
    //         } else if (mapRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
    //             mapRef.current.webkitRequestFullscreen();
    //         } else if (mapRef.current.msRequestFullscreen) { // IE/Edge
    //             mapRef.current.msRequestFullscreen();
    //         }
    //         if(mapType === "country"){
    //             countryIsFullScreen === true ? setCountryIsFullScreen(false) : setCountryIsFullScreen(true)
    //         }else{
    //             cityIsFullScreen === true ? setCityIsFullScreen(false) : setCityIsFullScreen(true)
    //         }
    //     }
    // };

    // useEffect(() => {
    //     const handleFullScreenChange = () => {
    //         setCountryIsFullScreen(!!document.fullscreenElement);
    //     };

    //     document.addEventListener('fullscreenchange', handleFullScreenChange);

    //     return () => {
    //         document.removeEventListener('fullscreenchange', handleFullScreenChange);
    //     };
    // }, []);


    const handleFullScreen = (mapType) => {
        const mapRef = mapType === "country" ? countryMapRef : cityMapRef;

        if (mapRef.current) {
            const element = mapRef.current;
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
            }
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (document.fullscreenElement === countryMapRef.current) {
                setCountryIsFullScreen(true);
                setCityIsFullScreen(false);
            } else if (document.fullscreenElement === cityMapRef.current) {
                setCityIsFullScreen(true);
                setCountryIsFullScreen(false);
            } else {
                setCountryIsFullScreen(false);
                setCityIsFullScreen(false);
            }
        };

        // Listen for fullscreen changes
        document.addEventListener("fullscreenchange", handleFullScreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
        document.addEventListener("mozfullscreenchange", handleFullScreenChange);
        document.addEventListener("MSFullscreenChange", handleFullScreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullScreenChange);
            document.removeEventListener("MSFullscreenChange", handleFullScreenChange);
        };
    }, []);











    // Continentをselectしてcityテーブルをフィルター
    const [selectedContinent, setSelectedContinent] = useState("all");
    const filteredCities = selectedContinent === "all" ? sortedCities : sortedCities.filter(city => city.continent === selectedContinent);

    const handleSelectChange = (option) => {
        const selectedContinent = option?.value;
        setSelectedContinent(selectedContinent);
    };

    const handleTypeChange = (option) => {
        const selectedType = option?.value;
        switchTab(selectedType)
    };

    const cityContinentOptions = [
        {value: "all", label: "All Continents"},
        {value: "AS", label: "Asia"},
        {value: "AF", label: "Africa"},
        {value: "EU", label: "Europe"},
        {value: "NA", label: "North America"},
        {value: "SA", label: "South America"},
        {value: "OC", label: "Oceania"},
    ]
    const typeOptions = [
        {value: "country", label: "Countries"},
        {value: "city", label: "Cities"},
    ]

    const varColor = {
        "darkGray": "#303030",
        "lightGray": "#F9FAFB"
    }

    const customStyles = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkGray : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            // borderColor: theme == "dark" ? '#666' : '#ccc', // Change border color on focus
            // borderColor: state.isFocused ? (theme == "dark" ? "#fff" : "#fff") : (theme == "dark" ? "#fff" : "#fff"),
            // borderColor: state.isSelected ? (theme == "dark" ? "#fff" : "#fff") : (theme == "dark" ? "#fff" : "#fff"),
            borderRadius: "5px",
            boxShadow: 'none',
            width: "135px",
            height: "30px",
            minHeight: "0px",
            maxHeight: "30px"
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            // padding: '0 6px'
            paddingLeft: "0px",
            paddingTop: "0px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: dataTheme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 10px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "14px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            // backgroundColor: state.isSelected 
            //     ? (theme === "dark" ? "#333" : "#666")  // Color when selected
            //     : state.isFocused 
            //         ? (theme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
            //         : (theme === "dark" ? "#000" : "#fff"),  // Normal background
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkGray : "#fff"),  // Normal background
            // backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#e6e6e6' : '#fff', // Change background color on select/hover
            // backgroundColor: theme == "dark" ? "black" : "white",
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "14px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkGray : '#fff', // Background color of the dropdown
            width: "135px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            // color: state.isFocused ? (theme == "dark" ? '#fff' : '#ccc') : (theme == "dark" ? '#fff' : '#ccc'),
            // color: state.isSelected ? (theme == "dark" ? '#fff' : '#ccc') : (theme == "dark" ? '#fff' : '#ccc'),
            // color: theme == "dark" ? '#fff' : '#ccc', // Customize the arrow color
            // color: theme === "dark" ? '#fff' : '#666',
            // color: state.isFocused ? "hsl(255, 255 , 255, 100%)" : "hsl(255, 255 , 255, 100%)",
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
    };

    const typeSelectStyle = {
        control: (provided, state) => ({
            // ...provided,
            display: "flex",
            alignItems: "center",
            border: dataTheme === "dark" ? 0 : "0.5px solid gray",
            cursor: 'pointer', // Add cursor pointer
            backgroundColor: dataTheme === "dark" ? varColor.darkGray : "#fff", // Change background color
            color: dataTheme === "dark" ? "#fff" : "black", // Default text color
            borderRadius: "5px",
            boxShadow: 'none',
            width: "100px",
            height: "28px",
            minHeight: "0px",
            maxHeight: "28px"
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '28px',
            // padding: '0 6px'
            paddingLeft: "0px",
            // paddingTop: "0px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: '#666', // Background color of the selected value
            color: dataTheme === "dark" ? "#fff" : "black", // Text color of the selected value
            padding: '5px 10px', // Padding for better spacing
            borderRadius: '4px', // Rounded edges for the selected value background
            fontSize: "13px",
            whiteSpace: 'nowrap', // Prevent text wrapping
            overflow: 'visible', // Ensure full text is visible
            textOverflow: 'clip', // Prevents ellipsis
            lineHeight: '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer', // Add cursor pointer for options
            backgroundColor: state.isFocused 
                    ? (dataTheme === "dark" ? "#444" : "#e6e6e6")  // Color when hovered/focused
                    : (dataTheme === "dark" ? varColor.darkGray : "#fff"),  // Normal background
            color: state.isSelected
                ? (dataTheme === "dark" ? "white" : "#000") // Selected color for dark and light themes
                : state.isFocused
                ? (dataTheme === "dark" ? "white" : "black") // Hover color for dark and light themes
                : dataTheme === "dark"
                ? "#fff" // Normal color for dark theme
                : "black", // Normal color for light theme
            fontSize: "13px",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: dataTheme==="dark" ? varColor.darkGray : '#fff', // Background color of the dropdown
            width: "100px",
        }),
        indicatorSeparator: () => ({
            display: 'none' // Remove the line between the text and the dropdown arrow
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: "0px",
            margin: "auto",
            marginRight: "5px"
        }),
        dropdownIndicator: (provided, state) => ({//Arrow on the Right
            // ...provided,
            color: state.isSelected
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Selected color for dark and light themes
            : state.isFocused
            ? (dataTheme === "dark" ? "white" : "#1E1E1E") // Hover color for dark and light themes
            : dataTheme === "dark"
            ? "#fff" // Normal color for dark theme
            : "#1E1E1E", // Normal color for light theme
            width: "20px",
            height: "20px",
            opacity: "1",
            transition: "none",
            borderLeft: "none",
            padding: "auto"
        }),
    }





    return (
        <div className='home-profile'>
            <Helmet>
                <title>{homeUser ? `${homeUser.homeUser.username}'s Travels | BokenMap` : "BokenMap"}</title>
                <meta name='description' content={`${homeUser.homeUser.username}'s travels recorded on BokenMap.`} />
                <meta name="keywords" content="travel, map, bokenmap, boken, travels, record, travel diary" />

                <meta name="robots" content="index, follow" />

                <meta property="og:title" content={homeUser ? `${homeUser.homeUser.username}'s Travels | BokenMap` : "BokenMap"} />
                <meta property="og:description" content={`${homeUser.homeUser.username}'s travels recorded on BokenMap.`} />
                {/* 動的OGP画像生成！！！！ */}
                <meta property="og:image" content="https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" />
                <meta property="og:url" content="https://bokenmap.com/" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={homeUser ? `${homeUser.homeUser.username}'s Travels | BokenMap` : "BokenMap"} />
                <meta name="twitter:description" content={`${homeUser.homeUser.username}'s travels recorded on BokenMap.`} />
                {/* 動的OGP画像生成！！！！ */}
                <meta name="twitter:image" content="https://pbs.twimg.com/media/GdZhWu9bwAAYz91?format=jpg&name=large" />

                <link rel="icon" href="favicon.ico" type="image/x-icon" />
            </Helmet>

            <div className='top-strip'>
                <div className='map-legend-container'>
                    <div className='map-legend'>
                        <div className='map-legend-each'>
                            <div className='legend-icon legend-lived-icon' style={{backgroundColor: homeColors.lived}}></div>
                            <div className='legend-text'>Lived</div>
                        </div>
                        <div className='map-legend-each'>
                            <div className='legend-icon legend-visited-icon' style={{backgroundColor: homeColors.visited}}></div>
                            <div className='legend-text'>Visited</div>
                        </div>
                        <div className='map-legend-each'>
                            <div className='legend-icon legend-transited-icon' style={{backgroundColor: homeColors.transited}}></div>
                            <div className='legend-text'>Transited</div>
                        </div>
                    </div>
                    <div className='cats-info-container'>
                            <Tooltip id='cats-info-button' />
                            <FontAwesomeIcon icon={faCircleInfo} onClick={()=>{window.scrollTo(0, 0); navigate(`/info/category`)}} className='cats-info-icon' data-tooltip-content="Category Info" data-tooltip-id="cats-info-button" />
                    </div>
                </div>
                <div className='home-switch'>
                    <button className={`country-tab ${activeTab === 'country' ? 'active' : ''}`} onClick={() => switchTab('country')} style={activeTab === 'country' ? {backgroundColor: homeColors.ui} : {}}>Countries</button>
                    <button className={`city-tab ${activeTab === 'city' ? 'active' : ''}`} onClick={() => switchTab('city')} style={activeTab === 'city' ? {backgroundColor: homeColors.ui} : {}}>Cities</button>
                    <div className={`indicator ${activeTab}`} style={{backgroundColor: homeColors.ui}}></div>
                </div>
                <Select options={typeOptions} className='type-select' value={typeOptions.find(option => option.value === activeTab)} onChange={handleTypeChange} styles={typeSelectStyle} isSearchable={false} />
            </div>



            <div className='map-container' style={{display: activeTab === "country" ? "block" : "none"}}>
                <CountryMap dataTheme={dataTheme} homeUser={homeUser} embed={false} isFullScreen={countryIsFullScreen} sortCountries={sortCountries} sortedCountries={sortedCountries} getFlagEmoji={getFlagEmoji} ref={countryMapRef} homeColors={homeColors} />
                <div className='map-button-container'>
                    {homeUser.match && (
                        <div className='country-edit-button' onClick={() => {window.scrollTo(0, 0); navigate(`/edit/country`);}} data-tooltip-content="Edit Countries" data-tooltip-id="country-edit-button">
                            <Tooltip id='country-edit-button' />
                            <FontAwesomeIcon icon={faPen} className='edit-icon' />
                        </div>
                    )}
                    {homeUser.match && (
                        <div className='country-code-button' onClick={()=>copyEmbedCode("country", homeUser.homeUser.handle, dataTheme)} data-tooltip-content="Copy Embed Code" data-tooltip-id="country-code-button">
                            <Tooltip id='country-code-button' />
                            <FontAwesomeIcon icon={faCode} className='code-icon' />
                        </div>
                    )}
                    {homeUser.match && (
                        <div className='country-download-button' onClick={()=>downloadCountryMap(dataTheme)} data-tooltip-content="Download Image" data-tooltip-id="country-download-button">
                            <Tooltip id='country-download-button' />
                            <FontAwesomeIcon icon={faImage} className='image-icon' />
                        </div>
                    )}
                    <div className='country-fullscreen-button' onClick={()=>handleFullScreen("country")}>
                        <FontAwesomeIcon icon={faExpand} className='fullscreen-icon' />
                    </div>
                </div>
            </div>
            <div className='map-container' style={{display: activeTab == "city" ? "block" : "none"}}>
                <CityMap dataTheme={dataTheme} homeUser={homeUser} embed={false} isFullScreen={cityIsFullScreen} isEdit={false} ref={cityMapRef} homeColors={homeColors} />
                <div className='attribution-button'>
                    <FontAwesomeIcon icon={faCopyright} className='attribution-icon' data-tooltip-content="Powered by Geoapify | © OpenMapTiles © OpenStreetMap contributors" data-tooltip-id="attribution-icon" />
                    <Tooltip id='attribution-icon' />
                </div>
                <div className='map-button-container'>
                    {homeUser.match && (
                        <div className='country-edit-button' onClick={() => {window.scrollTo(0, 0); navigate(`/edit/city`);}} data-tooltip-content="Edit Cities" data-tooltip-id="city-edit-button">
                            <Tooltip id='city-edit-button' />
                            <FontAwesomeIcon icon={faPen} className='edit-icon' />
                        </div>
                    )}
                    {homeUser.match && (
                        <div className='country-code-button' onClick={()=>copyEmbedCode("city", homeUser.homeUser.handle, dataTheme)} data-tooltip-content="Copy Embed Code" data-tooltip-id="city-code-button">
                            <Tooltip id='city-code-button' />
                            <FontAwesomeIcon icon={faCode} className='code-icon' />
                        </div>
                    )}
                    {homeUser.match && (
                        <div className='country-download-button' onClick={handleCityMapDownload} data-tooltip-content="Download Image" data-tooltip-id="city-download-button">
                            <Tooltip id='city-download-button' />
                            <FontAwesomeIcon icon={faImage} className='image-icon' />
                        </div>
                    )}
                    <div className='country-fullscreen-button' onClick={()=>handleFullScreen("city")}>
                        <FontAwesomeIcon icon={faExpand} className='fullscreen-icon' />
                    </div>
                </div>
            </div>


            {/* <div className='map-legend-container'>
                <div className='map-legend'>
                    <div className='map-legend-each'>
                        <div className='legend-icon legend-lived-icon'></div>
                        <div className='legend-text'>Lived</div>
                    </div>
                    <div className='map-legend-each'>
                        <div className='legend-icon legend-visited-icon'></div>
                        <div className='legend-text'>Visited</div>
                    </div>
                    <div className='map-legend-each'>
                        <div className='legend-icon legend-transited-icon'></div>
                        <div className='legend-text'>Transited</div>
                    </div>
                </div>
            </div> */}

            <div className='home-bar' id="data">
                <div className='home-bar-left'>
                    <img className='home-bar-image' src={homeUser.homeUser.image}></img>
                    <div className='home-bar-left-buttons'>
                        {homeUser.match && (
                            <div className='home-bar-edit' onClick={() => {window.scrollTo(0, 0); navigate(`/dashboard/profile`)}} data-tooltip-content="Edit Profile" data-tooltip-id="profile-edit-button">
                                <Tooltip id='profile-edit-button' place="right" />
                                <FontAwesomeIcon icon={faPen} className='edit-profile-icon' />
                            </div>
                        )}
                        {homeUser.match && (
                            <div className='home-bar-share' onClick={() => navigate(`/share/${homeUser.homeUser.handle}`, {state: {homeUser}})} data-tooltip-content="Share Profile" data-tooltip-id="profile-share-button">
                                <Tooltip id='profile-share-button' place="right" />
                                <FontAwesomeIcon icon={faArrowUpFromBracket} className='share-profile-icon' />
                            </div>
                        )}
                    </div>
                </div>
                <div className='home-bar-data'>
                    {activeTab === "country" && (
                        <div className='country-info-container'>
                            <Tooltip id='country-info-button' />
                            <FontAwesomeIcon icon={faCircleInfo} onClick={()=>{window.scrollTo(0, 0); navigate(`/info/whatsacountry`)}} className='country-info-icon' data-tooltip-content="What is a country?" data-tooltip-id="country-info-button" />
                        </div>
                    )}
                    {activeTab === "country" && (
                    <>
                        <div className='home-bar-data-each'>
                            <div className='home-bar-data-each-top'><p>{visitedUnCountries.length ?? "-"}<span>/193</span></p></div>
                            {/* <div className='home-bar-data-each-top'><p><CountAnimation number={89} className="number-of-countries" /><span>/193</span></p></div> */}
                            <p className='home-bar-data-each-bottom'>Countries</p>
                        </div>
                        <div className='home-bar-data-each'>
                            <div className='home-bar-data-each-top'><p>{visitedUnContinents.length}<span>/7</span></p></div>
                            <p className='home-bar-data-each-bottom'>Continents</p>
                        </div>
                    </>)}
                    {activeTab === "city" && (
                    <>
                        <div className='home-bar-data-each'>
                            <div className='home-bar-data-each-top'><p>{visitedCities.length ?? "-"}</p></div>
                            <p className='home-bar-data-each-bottom'>Cities</p>
                        </div>
                        <div className='home-bar-data-each'>
                            <div className='home-bar-data-each-top'><p>{visitedCityContinents.length ?? "-"}<span>/7</span></p></div>
                            <p className='home-bar-data-each-bottom'>Continents</p>
                        </div>
                    </>)}
                </div>
            </div>

            <div className='home-info'>
                <div className='home-left'>
                    <p className='home-left-username'>{homeUser.homeUser.username}</p>
                    <p className='home-left-handle'>@{homeUser.homeUser.handle}</p>
                    {/* <div className='home-left-strip'> */}
                        {/* <div style={{display: "flex"}}> */}
                            {/* <div className='home-left-follow' style={{backgroundColor: homeColors.ui}}>Follow</div> */}
                            {/* <div className='home-left-share' onClick={() => navigate(`/share/${homeUser.homeUser.handle}`, {state: {homeUser}})}>
                                <FontAwesomeIcon icon={faQrcode} />
                            </div> */}
                            {/* <div className="home-left-share" onClick={() => navigate(`/share/${homeUser.homeUser.handle}`, {state: {homeUser}})}>Share</div> */}
                        {/* </div> */}
                    {/* </div> */}

                    {homeUser.homeUser.desc ? 
                        <div className='home-left-row-desc'>
                            <p>{homeUser.homeUser.desc}</p>
                        </div> : "" }
                    {homeUser.homeUser.home ? 
                        <div className='home-left-row'>
                            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Home-icon.svg/640px-Home-icon.svg.png' className='home-left-icon'></img>
                            <p>{homeUser.homeUser.home}, {countryData[`${homeUser.homeUser.homeCountry}`].country}</p>
                        </div> : "" }
                    {homeUser.homeUser.nationality ? 
                        <div className='home-left-row'>
                            <img src='https://uxwing.com/wp-content/themes/uxwing/download/location-travel-map/flag-icon.png' className='home-left-icon'></img>
                            <p>{countryData[`${homeUser.homeUser.nationality}`].country}</p>
                        </div> : "" }
                    {homeUser.homeUser.website ? 
                        <div className='home-left-row'>
                            <img src='https://cdn-icons-png.flaticon.com/512/1006/1006771.png' className='home-left-icon'></img>
                            <a href={homeUser.homeUser.website} className='home-left-website' target='_blank' style={{color: homeColors.ui}}>{homeUser.homeUser.website}</a>
                        </div> : "" }

                    {!homeUser.homeUser.desc && !homeUser.homeUser.home && !homeUser.homeUser.nationality && !homeUser.homeUser.website && (
                        <div className='no-info-edit-profile-button-container'>
                            <div className='no-info-edit-profile-button' onClick={() => {window.scrollTo(0, 0); navigate(`/dashboard/profile`)}} style={{backgroundColor: homeColors.ui}}>Edit Profile</div>
                        </div>
                    )}      

                </div>

                {activeTab === "country" && (
                    <div className='home-right'>
                        {["Asia", "Africa", "Europe", "Oceania", "North America", "South America"].map(continent => {
                            const count = countryInContinent[continent] || 0;

                            const totalCountries = {
                                "Asia": 49,
                                "Europe": 51,
                                "Africa": 54,
                                "Oceania": 14,
                                "North America": 23,
                                "South America": 12
                            }
                            const totalCountry = totalCountries[continent]  
                            const widthPercentage = (count / totalCountry) * 100;

                            return (
                                <div className="home-right-row">
                                    <Box key={continent}   style={{
                                        // width: '35%',
                                        flexGrow: 1,
                                        maxWidth: '500px',
                                        marginRight: '8px',
                                    }}>
                                        <div style={{ 
                                            backgroundColor: dataTheme === "dark" ? "#404040" : "#e6e6e6", 
                                            height: '12px', 
                                            width: '100%', 
                                            borderRadius: '2.5px',
                                            overflow: "hidden"
                                        }} className='home-right-progress'>
                                            <div style={{ 
                                                backgroundColor: homeColors.ui, 
                                                height: '100%', 
                                                width: `${widthPercentage}%`, 
                                                borderRadius: '0px',
                                            }} />
                                        </div>
                                    </Box>
                                    <p className="home-right-count-text"><span>{count}</span>/{totalCountry}</p>
                                    <p className='home-right-continent-text'>{continent}</p>
                                </div>
                            );
                        })}
                        {/* {Object.keys(continentTotal).map(continent => (
                            <div className="home-right-row" id={continent} key={continent}>
                                <div style={{ 
                                    backgroundColor: '#e6e6e6', 
                                    height: '11px', 
                                    width: '97px', 
                                    borderRadius: '4px',
                                    overflow: 'hidden'
                                }}>
                                    <div
                                        id={`${continent}-progress`}
                                        style={{ 
                                            backgroundColor: "#0d780d", 
                                            height: '100%', 
                                            width: '0%',
                                            borderRadius: '0px',
                                            // transition: 'width 0.3s ease-out'
                                            transition: "width 0.4s ease-in-out"
                                        }}
                                        className='progress-bar'
                                    />
                                </div>
                                <p className="home-right-count-text">
                                    <span className="progress-value">{countryInContinent[`${continent}`] || 0}</span>/{continentTotal[continent]}
                                </p>
                                <p className='home-right-continent-text'>{continent}</p>
                            </div>
                        ))} */}
                    </div>
                )}

                {activeTab === "city" && (
                    <div className='home-right'>
                        <CityPieChart homeUser={homeUser} mapColor={homeUser.homeUser.plus === true  ? (homeUser.homeUser.color || "green")  : "green"} />
                    </div>
                )}

            </div>








            {/* {activeTab === "country" && (
            <div className='home-country-list'>
                <table className='home-country-table'>
                    <thead>
                        <tr>
                            <th className='country-th-status' onClick={{}}><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Status</th>
                            <th className='country-th-country'><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Country</th>
                            <th className='country-th-continent'><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Continent</th>
                        </tr>
                    </thead>
                    <tbody className='tbody'>
                    {homeUser.countries.map((country, index) => (
                    <tr key={index}>
                        <td className='country-td-status'>
                            {country.status == "1" && (<div className='status-one status-icon'></div>)}
                            {country.status == "2" && (<div className='status-two status-icon'></div>)}
                            {country.status == "3" && (<div className='status-three status-icon'></div>)}
                        </td>
                        <td className='country-td-country'><span className='flag-emoji'>{getFlagEmoji(country.shortName)}</span>{country.name}</td>
                        <td className='country-td-continent'>{country.continent}</td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            )} */}

            {activeTab === "country" && (
            <div className='home-country-list'>
                <div className='home-country-table-wrapper'>
                    <table className='home-country-table'>
                        <thead>
                            <tr>
                                <th className='country-th-status'>
                                    <div onClick={() => sortCountries('status')} style={{display: "flex", justifyContent: "center", alignItems: "center"}}><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img><div style={{marginLeft: "5px"}}>Status</div></div>
                                </th>
                                <th className='country-th-country'>
                                    <div onClick={() => sortCountries('name')} style={{display: "flex", alignItems: "center"}}><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img><div style={{marginLeft: "5px"}}>Country</div></div>
                                </th>
                                <th className='country-th-continent' style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div onClick={()=> sortCountries('continent')} style={{display: "flex", alignItems: "center"}}><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img><div style={{marginLeft: "5px"}}>Continent</div></div> 
                                    {user.login && (
                                        <div onClick={()=>{navigate("/edit/country")}} className='table-country-edit-button' data-tooltip-content="Edit Countries" data-tooltip-id="table-country-edit-button">
                                            <Tooltip id='table-country-edit-button' />
                                            <FontAwesomeIcon icon={faPen} className='table-country-edit-icon' />
                                        </div>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                        {sortedCountries.map((country, index) => (
                        <tr key={index}>
                            <td className='country-td-status'>
                                {country.status == "1" && (<div className='status-one status-icon' style={{backgroundColor: homeColors.lived}}></div>)}
                                {country.status == "2" && (<div className='status-two status-icon' style={{backgroundColor: homeColors.visited}}></div>)}
                                {country.status == "3" && (<div className='status-three status-icon' style={{backgroundColor: homeColors.transited}}></div>)}
                            </td>
                            <td className='country-td-country'><span className='flag-emoji'>{getFlagEmoji(country.shortName)}</span>{country.name}</td>
                            <td className='country-td-continent'>{country.continent}</td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {sortedCountries.length == 0 &&
                        (
                            <div className='table-add-country-button-container'>
                                <div className='table-add-country-button' style={{backgroundColor: homeColors.ui}} onClick={()=>{navigate("/edit/country")}}>Add Country</div>
                            </div>
                        )
                }
            </div>
            )}













            {activeTab === "city" && (
                <div className='city-continent-container'>
                    <Select options={cityContinentOptions} className='city-continent-select' value={cityContinentOptions.find(option => option.value === selectedContinent)} onChange={handleSelectChange} styles={customStyles} isSearchable={false} />
                    {/* <select id="continentFilter" className="city-continent-select" onChange={handleContinentChange}>
                        <option value="all" selected>All Continents</option>
                        <option value="AS">Asia</option>
                        <option value="AF">Africa</option>
                        <option value="EU">Europe</option>
                        <option value="NA">North America</option>
                        <option value="SA">South America</option>
                        <option value="OC">Oceania</option>
                    </select> */}
                </div>
            )}

            {activeTab === "city" && (
                <div className='home-city-list'>
                    <div className='home-city-table-wrapper'>
                        <table className='home-city-table'>
                            <thead>
                                <tr>
                                    <th className='city-th-status' onClick={() => sortCities('status')}>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Status
                                        </div>
                                    </th>
                                    <th className='city-th-city' onClick={() => sortCities('city')}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>City
                                        </div>
                                    </th>
                                    {/* <th className='city-th-country' onClick={() => sortCities('country')}>
                                        <img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img>Country
                                    </th> */}
                                    <th className='city-th-continent' style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "160px"}}>
                                        <div onClick={()=> sortCities('country')} style={{display: "flex", alignItems: "center", cursor: "pointer"}}><img src="https://cdn-icons-png.freepik.com/512/3545/3545579.png" className="sort-icon" alt="Sort Icon"></img><div style={{marginLeft: "5px"}}>Country</div></div>
                                        {user.login && (
                                            <div onClick={()=>{navigate("/edit/city"); window.scrollTo(0, 0);}} className='table-city-edit-button' data-tooltip-content="Edit Cities" data-tooltip-id="table-city-edit-button">
                                                <Tooltip id='table-city-edit-button' />
                                                <FontAwesomeIcon icon={faPen} className='table-city-edit-icon' />
                                            </div>
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                            {/* {sortedCities.map((city, index) => (
                            <tr key={index}>
                                <td className='city-td-status'>
                                    {city.status == "1" && (<div className='status-one status-icon'></div>)}
                                    {city.status == "2" && (<div className='status-two status-icon'></div>)}
                                    {city.status == "3" && (<div className='status-three status-icon'></div>)}
                                </td>
                                <td className='city-td-city'>{city.city}</td>
                                <td className='city-td-country'><span className='flag-emoji'>{getFlagEmoji(city.country)}</span>{countryData[city.country].country}</td>
                            </tr>
                            ))} */}
                            {filteredCities.map((city, index) => (
                            <tr key={index}>
                                <td className='city-td-status'>
                                    {city.status == "1" && (<div className='status-one status-icon' style={{backgroundColor: homeColors.lived}}></div>)}
                                    {city.status == "2" && (<div className='status-two status-icon' style={{backgroundColor: homeColors.visited}}></div>)}
                                    {city.status == "3" && (<div className='status-three status-icon' style={{backgroundColor: homeColors.transited}}></div>)}
                                </td>
                                <td className='city-td-city'>{city.city}</td>
                                <td className='city-td-country'><span className='flag-emoji'>{getFlagEmoji(city.country)}</span>{countryData[city.country].country}</td>
                            </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    {filteredCities.length == 0 &&
                        (
                            <div className='table-add-city-button-container'>
                                <div className='table-add-city-button' style={{backgroundColor: homeColors.ui}} onClick={()=>{navigate("/edit/city")}}>Add City</div>
                            </div>
                        )
                }
                </div>
            )}
 
        </div>
    );
}

export default HomeProfile;