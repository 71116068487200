import React from 'react';
import { Helmet } from 'react-helmet-async';

function Attribution() {
    return (
        <div className='page' style={{display: "flex", flexDirection: "column"}}>
            <Helmet>
                <title>Attribution | BokenMap</title>
            </Helmet>
            <h1>Attribution</h1>

            <ul>
                <li>
                    <h4>City Map</h4>
                    <p>Powered by <a href="https://www.geoapify.com/">Geoapify</a></p>
                </li>
            </ul>

        </div>
    );
}

export default Attribution;



