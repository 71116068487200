import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useToast } from './ToastContext';

import "../stylesheets/Create.css";

const Create = ({theme, newUser}) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [handle, setHandle] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (/\s/.test(handle)) {
      setError("Handle cannot contain any spaces.");
      return;
    }
    if (!handle || handle.trim() === "") {
      setError("Handle cannot be empty.");
      return;
    }
    if (handle.length < 4 || handle.length > 10) {
      setError("Your handle should be over 4, under 10 letters");
      return;
    }
    if (!/^[a-z0-9_]+$/.test(handle)) {
      setError("Handle can only contain lowercase letters, numbers, and underscores.");
      return;
    }


    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/create`, { method: "POST", headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ handle, newUser }),
    }).then((response) => {
      // console.log("RESPONSE", response);
      if (!response.ok) {
        // Handle server-side errors
        return response.json().then((errorData) => {
          throw new Error(errorData.message || "Failed to create handle");
        });
      }
      return response;
    }).then(data => {
      window.location.href = `${process.env.REACT_APP_BACK_DOMAIN}/created`;
      // window.location.href = `/${handle}`
      // navigate(`/`);
    }).catch((err) => {
        setError(err.message);
    });
  };



  useEffect(() => {
    document.body.classList.add('create-page');
    if(theme=="dark"){
        document.body.classList.add('create-dark-body');
    }else{
        document.body.classList.add('create-light-body');
    }
    return () => {
        document.body.classList.remove('create-page');
    };
  }, []);



  return (
    <div className="create-page">
        <div className="create-header">
            <div className="nav-left" style={{display: "flex", justifyContent: "center", transform: "scale(0.8)"}}>
                <div className="nav-left-icon"></div>
                <h1 className="nav-left-name">BokenMap</h1>
            </div>
            <h1 className="create-account-title">Create Account</h1>
        </div>

        <div className="create-container">
            <h3 className="create-handle-title">Set Your Handle</h3>
            <p className="create-handle-warning">This cannot be changed later.</p>
            {/* <p>Your handle should not contain any spaces or special characters.</p> */}
            <div className="create-error-row">
              <p className="create-error-text">{error && error}</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="create-input-row">
                    <span className="create-handle-domain">bokenmap.com/</span>
                    <input
                    type="text"
                    value={handle}
                    onChange={(e) => setHandle(e.target.value)}
                    placeholder="Enter your handle"
                    className="create-handle-input"
                    />
                </div>

                <div className="create-terms-row">
                  <div className="create-terms-text">By creating an account, you are agreeing to the <a href="/terms" target="_blank" rel="noreferrer" className="create-terms-link">Terms of Service</a> and <a href="/privacy" target="_blank" rel="noreferrer" className="create-terms-link">Privacy Policy</a>.</div>
                </div>

                <div className="create-button-row">
                  <button type="submit" className="create-submit-button">Create Account</button>
                </div>
            </form>
        </div>

        <Link to="/" className="create-cancel-link">Cancel Registration</Link>



    </div>
  );
};


export default Create;
