import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CreateProtected = ({ children }) => {
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/create/check`, {method: "GET",credentials: "include",});

        if (!response.ok) {
          throw new Error("Access forbidden");
        }

        const data = await response.json();

        setNewUser(data.passport)

        if (!data.newUser) {
          navigate("/");
        }
      } catch {
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    checkAccess();
  }, []);

  if (isLoading) {
    return null;
  }

  return React.cloneElement(children, { newUser });;
};

export default CreateProtected;
