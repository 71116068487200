import React from 'react';
import { Helmet } from 'react-helmet-async';

function Terms() {
    return (
        <div>
            <Helmet>
                <title>Terms of Service | BokenMap</title>
            </Helmet>
            <h1>Terms of Service</h1>

            <h4>Last Updated: Dec 10, 2024</h4>

            The following terms govern your use of bokenmap.com, any related mobile apps, software or features (collectively referred to as the "Platform" or "BokenMap").
            When I use terms like "I", "me", or "my" in these Terms, I'm referring to myself as the individual owner and operator of BokenMap. By using BokenMap, you agree to these Terms of Service ("Terms") as well as any additional policies linked here and on the Platform. Please read these Terms carefully. If you don't agree, please don't use BokenMap.

            <h2>1.Changes to these Terms</h2>
            I may make changes to the Platform or these Terms from time to time. I can make these changes at any time and it's your responsibility to check these Terms now and then for any changes. Your continued use after any changes constitutes acceptance of the revised Terms. If you don't agree with the changes, please stop using BokenMap.

            <h2>2.Your Account</h2>
            To create an account, you must be at least 18 years old. When you create an account, you agree to comply with these Terms and that you're over 18 and legally able to enter these Terms with me. You're responsible for your account and must provide accurate information about yourself. Do not share your login details. Contact me immediately if you suspect your account was compromised. You must not assign or transfer your account to someone else or use your account (or allow it to be used by anyone) in a way which in our reasonable opinion, causes damage to BokenMap, or infringes another's rights or applicable laws and regulations.

            <h2>3.Your Handle</h2>
            When you create an account, you'll need to choose a suitable handle that respects everyone's rights. We understand your handle holds significance, but it must also be appropriate for all users and avoid infringing on anyone's rights, including intellectual property (IP) rights like copyright and trademarks. You can't use someone else's name, like a celebrity or brand, or pick a username that's offensive, vulgar, or unrelated just to profit from it later (referred to as “Domain Squatting”).
            If any issues arise with your handle, we'll assess the situation fairly and may ask you to change it (it might be reassigned too). If you decline, we may suspend or terminate your account. If someone claims your handle violates their IP rights, they must file an Intellectual Property report, and you'll have the chance to respond with a Counter Notice. If you haven't logged in or seen traffic in your account for the past 6 months, we might reclaim or reassign your handle (but we'll always contact you first).

            <h2>4.Your Content</h2>
            When I talk about your “content”, I mean the text, graphics, links, and any other materials you add to your profile. You're responsible for your content, and you warrant that it doesn't infringe on anyone's rights or violate any laws. Don't post personal, harmful, or illegal content.

            <h2>5. Content Licensing</h2>
            When you post content on your BokenMap profile, you grant me a licence to (i) use, publicly display, distribute, modify, adapt and create derivative works of such content; and (ii) use your name, image, photograph, likeness and any other personal attributes in the content; on the Platform and in our marketing in all media (such as our social channels and any other advertising). This license is worldwide, royalty-free and perpetual, which means I can use your content anywhere in the world, without paying you fees, for as long as I like. You agree that you have all third party rights necessary to post the content on BokenMap and to grant me this license.
            You'll retain all of your rights in your content. Keep in mind that your content will be publicly accessible, and may be used and re-shared by others on BokenMap and across the internet.
            Please don't share personal info on BokenMap that you don't want visible to the world. Never post social security numbers, passport details or similar info that could cause harm in the wrong hands. You may only post another person's personal information where you have their consent and have kept a record of it. I don't have to monitor the accuracy, reliability or legality of your content, but I may choose to do so.
            I may modify, remove or restrict access to content at any time in line with these Terms or apply a sensitive content warning to content that I deem unsuitable for all audiences.

            <h2>6.Account Suspension/Termination</h2>
            If you breach these Terms, I may suspend or terminate your account. I'll generally try to notify you beforehand, but I reserve the right to act immediately if needed. You won't receive refunds for any paid services.

            <h2>7. Feedback</h2>
            Feedback is greatly appreciated on how to can make BokenMap even better! Keep in mind that if you share feedback, I am free to use it however I like, without payment to you (or to not use it at all).

            <h2>8.The Platform</h2>
            I grant you a limited right to use BokenMap for its intended purposes. All rights and intellectual property related to BokenMap are owned by me or my licensors. Don't copy, modify or attempt to gain unauthorized access.

            <h2>9.Fees and Payments</h2>
            Some BokenMap features may require payment. You must pay any applicable fees on time. I may change fees with advance notice.

            <h2>10.Privacy</h2>
            The privacy policy outlines how I handle your personal data. By using BokenMap, you agree to the privacy policy.

            <h2>11.Liability</h2>
            I'm not liable for any damages from your use of BokenMap. It's crucial that you maintain backups of your own content. My total liability is limited to fees paid in the prior 12 months or $50, whichever is lower.

            <h2>12.Disclaimers</h2>
            BokenMap is provided "as is" without warranties of any kind. I make no guarantees about availability, security, accuracy or suitability.

            <h2>13.Third Party Services</h2>
            BokenMap may integrate third party services which have separate terms you must review and accept.

            <h2>14.General</h2>
            These Terms are governed by the laws of Japan. If any part is unenforceable, the rest remains in effect. This constitutes the entire agreement between us related to BokenMap.


      {/* <h3>Any questions or comments? Please reach out from <a href="/contact">here</a>.</h3> */}

    
        </div>
    );
}

export default Terms;