import { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import '../stylesheets/Footer.css';

function Footer({user, handleLogout, handleModalOpen}) {
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);


    function getUserPreference() {
        if(window.localStorage.getItem('bokenmap-darkmode-theme')) {
          return window.localStorage.getItem('bokenmap-darkmode-theme')
        }
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }
    // const footer = document.getElementsByClassName(".footer")
    // footer.setAttribute('data-theme', getUserPreference());

    const toHomeTop = () => {
      if (location.pathname === `/${user.user.handle}`) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    return (
        <footer className="footer">
            <div className='footer-top'>
                <div className="footer-left">
                    <div className='footer-left-logo' onClick={() => navigate(`/`)}>
                        <div className='footer-left-icon'></div>
                        <div className='footer-left-name'>BokenMap</div>
                    </div>
                    {/* { user.user ? <div className='footer-left-row'><a href={`/${user.user.handle}`} className='footer-handle'>@{user.user.handle}</a><span style={{color: "gray"}}>-</span><Link onClick={handleLogout} className='footer-text footer-logout'>Logout</Link></div> : <Link onClick={handleModalOpen} className='footer-text footer-login'>Login</Link> } */}
                    { user.user ? <div className='footer-left-row'><Link to={`/${user.user.handle}`} onClick={toHomeTop} className='footer-handle'>@{user.user.handle}</Link></div> : <Link onClick={handleModalOpen} className='footer-text footer-login'>Login</Link> }
                </div>
                <div className="footer-right">
                    <Link to="/" className='footer-text'>Home</Link>
                    <Link to="/about" className='footer-text'>About</Link>
                    <Link to="/pricing" className='footer-text'>Pricing</Link>
                    <Link to="/terms" className='footer-text'>Terms</Link>
                </div>
            </div>
            <div className='footer-bottom'>
                <p className='footer-copyright footer-text'>© 2024 BokenMap</p>
                <Link to='/info/attribution' className='footer-credit footer-text'>Attribution</Link>
            </div>

        </footer>
    );
}


export default Footer;