import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import "../stylesheets/BillingSuccess.css"

function BillingSuccess({user, handleModalOpen}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [customer, setCustomer] = useState(null);
    const [googleFirstName, setGoogleFirstName] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const sessionId = searchParams.get('session_id');
        if(!sessionId){
            navigate('/');
            return
        }

        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/billing/success?session_id=${sessionId}`, { method: 'GET', credentials: 'include' })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch billing info');
          }
          return response.json();
        }).then((data) => {
            if (!data || !data.customer.email) {
                navigate('/');
            } else {
                setCustomer(data.customer);
                setGoogleFirstName(data.googleFirstName);
            }
        }).catch((err) => {
            console.error(err)
            navigate('/');
        }).finally(() => setLoading(false));
    }, [searchParams, navigate]);

    if (loading) {
        return <div className="loading"></div>;
    }

    function formatStripeName(name) {
        if (!name) return 'Guest';
        const firstName = name.split(' ')[0];
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    }

    const goToPlus = () => {
        if(user.login){
            // navigate('/dashboard/plus')
            window.location.href = "/dashboard/plus";
        }else{
            handleModalOpen()
        }
    }

    return (
        <div>
            <Helmet>
                <title>Subscribed | BokenMap</title>
            </Helmet>

            <div className='billing-success-container'>
                <FontAwesomeIcon icon={faCircleCheck} className='billing-success-check-icon' />
                <h1 className='billing-success-main-text'>Thanks for subscribing, {googleFirstName || formatStripeName(customer.name)}!</h1>
                <p className='billing-success-sub-text'>You now have access to Plus features. You can manage your subscription from the billing portal.</p>
                <div className='billing-success-button-container'>
                    <div onClick={goToPlus} className='billing-success-go-to-plus'>Go to Plus Settings</div>
                    <div onClick={()=>{window.open(`${process.env.REACT_APP_STRIPE_PORTAL_LINK}?prefilled_email=${customer && customer.email}`)}} className='billing-success-go-to-portal'>Go to Billing Portal</div>
                </div>
            </div>

            {/* <div>
                <div onClick={()=>{ window.open(`${process.env.REACT_APP_STRIPE_PORTAL_LINK}?prefilled_email=${customer && customer.email}`, "_blank")}}>Go to Billing Portal</div>
                <div onClick={() => navigate('/dashboard/plus')}>Go to Plus Settings</div>
            </div> */}

        </div>
    );
}

export default BillingSuccess;