import { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, Navigate, useParams } from 'react-router-dom';

import { ToastProvider } from './components/ToastContext';
import { Toaster, toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { Modal, Box, Typography, Button, Fade } from '@mui/material';


import './stylesheets/App.css';
import Landing from './pages/Landing';
import Home from './pages/Home';

import Create from './components/Create';
import CreateProtected from './components/CreateProtected';

import Share from './pages/Share';
import EmbedCountry from './components/embed/EmbedCountry';
import EmbedCity from './components/embed/EmbedCity';
import EditCountry from './pages/edit/EditCountry';
import EditCity from './pages/edit/EditCity';
import Dashboard from './pages/Dashboard';


import Pricing from './pages/Pricing';
import BillingSuccess from './pages/BillingSuccess';

import About from './pages/info/About';
import Support from './pages/info/Support';

import Notice from './pages/info/Notice';
import Terms from './pages/info/Terms';
import Privacy from './pages/info/Privacy';
import Commerce from './pages/info/Commerce';
import Contact from './pages/info/Contact';

import Info from './pages/info/Info';
import WhatsaCountry from './pages/info/WhatsaCountry';
import Category from './pages/info/Category';
import Extra from './pages/info/Extra';
import Attribution from './pages/info/Attribution';

import Error from './components/Error';

import Navbar from './design/Navbar';
import Footer from './design/Footer';

const ProtectedRoute = ({ element, user }) => {
  return user && user.login ? element : <Navigate to="/" />;
};


function App() {
  const navigate = useNavigate()
  const location = useLocation();
  const [user, setUser] = useState({login: "not loaded", user: null,});
  const [dataTheme, setDataTheme] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false)


  const [mapColor, setMapColor] = useState("green")

  const redirectToGoogle = () => {
    window.location.href = `${process.env.REACT_APP_BACK_DOMAIN}/auth/google`;
  }


  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/logout`, {method: "GET",credentials: "include"})
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            // ログアウト成功時にホームへリダイレクト
            navigate('/');
            window.location.reload(); // ログイン状態を更新
        } else {
            alert("ログアウトに失敗しました。");
        }
    }).catch((error) => {
        console.error("ログアウト中にエラーが発生しました:", error);
    });
  };

  // const setInitialTheme = `
    function getUserPreference() {
      // console.log(window.matchMedia('(prefers-color-scheme: light)').matches);
      if(window.localStorage.getItem('bokenmap-darkmode-theme')) {
        return window.localStorage.getItem('bokenmap-darkmode-theme')
      }
      return window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark'
    }
    document.body.dataset.theme = getUserPreference();
  // `;

  useEffect(() => {
    setDataTheme(getUserPreference())

    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/session`, {method: "GET", credentials: 'include', headers: {'Content-Type': 'application/json',},})
      .then(response => response.json())
      .then(data => {        
        if (data.login) {
          // console.log("api sessionからの", data);

          // fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users`, {method: 'POST', credentials: "include", headers: {'Content-Type': 'application/json',},
          //   body: JSON.stringify({handle: data.user.handle}),
          // }).then((response) => {
          //     if (!response.ok) {
          //       throw new Error(`HTTP error! Status: ${response.status}`);
          //     }
          //     return response.json();
          //   }).then((result) => {
              // console.log(result);
              
              // setUser({
              //   login: true,
              //   user: result.user,
              // });
              
              // if(result.user.plus){
              //   setMapColor(result.user.color || "green")
              // }else{
              //   setMapColor("green")
              // }
              // if (location.pathname === '/') {
              //   navigate(`/${data.user.handle}`);
              // }
            // }).finally(() => setIsLoading(false));
            setUser({
              login: true,
              user: data.user,
            });
            
            if(data.user.plus){
              setMapColor(data.user.color || "green")
            }else{
              setMapColor("green")
            }
            if (location.pathname === '/') {
              navigate(`/${data.user.handle}`);
            }
        } else {
          setUser({
            login: false,
            user: null,
          });
          setMapColor("green")
          // console.log('No active session');
        }
      })
      .catch(err => {
        console.error(err)
        setUser({
          login: false,
          user: null,
        });
        setMapColor("green")
      })
      .finally(() => setIsLoading(false));
  }, [navigate]);




  const showNavAndFooter = !location.pathname.startsWith('/embed/country') && !location.pathname.startsWith('/embed/city') && !location.pathname.startsWith('/edit/country') && !location.pathname.startsWith('/edit/city') && !location.pathname.startsWith('/create');

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  

  return (
    <div className="app">
      <Helmet>
        <title>BokenMap | Map & Show Off Where You've Traveled</title>
        <meta name="description" content="BokenMap is a tool for travelers to record visited countries and cities. You can add your countries and cities to your map and list, and you can download the image as well as embed the map on other websites." />
        <meta name="keywords" content="travel, map, bokenmap, boken, travels, record, travel diary" />
      </Helmet>

      <Toaster />

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "230px",
              height: "160px",
              // bgcolor: dataTheme === "dark" ? "#303030" : 'background.paper',
              bgcolor: dataTheme === "dark" ? "#303030" : '#f4f4f4;',
              boxShadow: 24,
              p: 3,
              borderRadius: 4,
              border: "none",
              outline: "none"
            }}
          >
            <div onClick={handleModalClose} className='modal-exit-container'>
              <FontAwesomeIcon icon={faXmark} className='modal-exit-icon' />
            </div>

            <div className='modal-logo'>
              <div className='modal-logo-icon'></div>
              <div className='modal-logo-text'>BokenMap</div>
            </div>

            <p className='modal-text'>Start Mapping Your Travels!</p>
            {/* <a href="/about" className='modal-link'>More Info</a> */}

            <div className='google-login-button-container'>
              <button className="gsi-material-button" onClick={()=>{window.location.href = `${process.env.REACT_APP_BACK_DOMAIN}/auth/google`}}>
                <div className="gsi-material-button-state"></div>
                <div className="gsi-material-button-content-wrapper">
                  <div className="gsi-material-button-icon">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      style={{ display: "block" }}
                    >
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span className="gsi-material-button-contents">Sign in with Google</span>
                  <span style={{ display: "none" }}>Sign in with Google</span>
                </div>
              </button>
            </div>

          </Box>
        </Fade>
      </Modal>




      {/* <Navbar user={user} handleLogout={handleLogout} /> */}
      {showNavAndFooter && <Navbar user={user} handleLogout={handleLogout} handleModalOpen={handleModalOpen} theme={dataTheme} setTheme={setDataTheme} />}
      <main className='main'>
      {isLoading ? (
          <div></div>
      ) : (
        <ToastProvider theme={dataTheme}>
          <Toaster />
          <Routes>
            <Route path="/:handle" element={<Home user={user} mapColor={mapColor} />} />
            <Route path="/" element={!user.login ? <Landing handleModalOpen={handleModalOpen} dataTheme={dataTheme} /> : <Navigate to={`/${user.user?.handle}`} />}/>
            <Route path="/" element={<Landing handleModalOpen={handleModalOpen} dataTheme={dataTheme} />} />

            <Route path="/create" element={<CreateProtected><Create theme={dataTheme} /></CreateProtected>} />

            <Route path="/share/:handle" element={<Share theme={dataTheme} />} />
            <Route path="/embed/country/:handle" element={<EmbedCountry mapColor={mapColor} />} />
            <Route path="/embed/city/:handle" element={<EmbedCity />} />
            <Route path="/edit" element={<Navigate to="/edit/country" />} />
            <Route path="/edit/country" element={<ProtectedRoute element={<EditCountry user={user} dataTheme={dataTheme} mapColor={mapColor} />} user={user} />} />
            <Route path="/edit/city" element={<ProtectedRoute element={<EditCity user={user} dataTheme={dataTheme} mapColor={mapColor} />} user={user} />} />
            <Route path="/dashboard/*" element={<ProtectedRoute element={<Dashboard theme={dataTheme} setTheme={setDataTheme} user={user} setUser={setUser} mapColor={mapColor} setMapColor={setMapColor} />} user={user} />} />

            <Route path="/pricing" element={<Pricing user={user} setUser={setUser} handleModalOpen={handleModalOpen} theme={dataTheme} />} />
            <Route path="/billing/success" element={<BillingSuccess user={user} handleModalOpen={handleModalOpen} />} />

            <Route path="/about" element={<About theme={dataTheme} handleModalOpen={handleModalOpen} />} />
            <Route path="/support" element={<Support />} />

            {/* <Route path="/terms" element={<Terms />} /> */}
            <Route path="/privacy" element={<Notice><Privacy /></Notice>} />
            <Route path="/terms" element={<Notice><Terms /></Notice>} />
            <Route path="/commerce" element={<Notice><Commerce /></Notice>} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/info" element={<Info />} />
            <Route path="/info/whatsacountry" element={<WhatsaCountry />} />
            <Route path="/info/category" element={<Category />} />
            <Route path="/info/extra" element={<Extra />} />
            <Route path="/info/attribution" element={<Attribution />} />

            <Route path="/landing" element={<Landing handleModalOpen={handleModalOpen} dataTheme={dataTheme} />} />

            <Route path="/error" element={<Error />} />

           </Routes>
          </ToastProvider>
      )}
      </main>
      {/* <Footer user={user} handleLogout={handleLogout} /> */}
      {showNavAndFooter && <Footer user={user} handleLogout={handleLogout} handleModalOpen={handleModalOpen} />}

    </div>
  );
}



export default App;
