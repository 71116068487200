import { useState, useEffect, useRef } from 'react';
import '../stylesheets/Navbar.css';
import { useNavigate, useLocation } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faBolt } from '@fortawesome/free-solid-svg-icons';

function Navbar({ user, handleLogout, handleModalOpen, theme, setTheme }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [AccountMenu, setAccountMenu] = useState(false);
    const [loadingUser, setLoadingUser] = useState(true);
    const accountMenuRef = useRef(null);
    const accountIconRef = useRef(null);

    const toggleAccountMenu = () => {
        setAccountMenu(!AccountMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (accountMenuRef.current && !accountMenuRef.current.contains(event.target) && accountIconRef.current && !accountIconRef.current.contains(event.target)) {
                setAccountMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Simulate user data loading process
        if (user.login !== "not loaded") {
            setLoadingUser(false);
        }
    }, [user]);





    const [activeTheme, setActiveTheme] = useState(document.body.dataset.theme);
    
    const inactiveTheme = activeTheme === "light" ? "dark" : "light";

    useEffect(() => {
        document.body.dataset.theme = activeTheme;
        window.localStorage.setItem("bokenmap-darkmode-theme", activeTheme);
        setTheme(activeTheme)
    }, [activeTheme]);

    // const [theme, setTheme] = useState(null)
    // function getUserPreference() {
    //     if(window.localStorage.getItem('bokenmap-darkmode-theme')) {
    //       return window.localStorage.getItem('bokenmap-darkmode-theme')
    //     }
    //     return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    //   }
    // useEffect(() => {
    //     // Set theme from localStorage or system preference
    //     const currentTheme = getUserPreference();
    //     setTheme(currentTheme);

    //     // Listen for changes to the localStorage
    //     const handleStorageChange = () => {
    //         setTheme(getUserPreference());
    //     };

    //     window.addEventListener('storage', handleStorageChange);

    //     // Cleanup the event listener
    //     return () => {
    //         window.removeEventListener('storage', handleStorageChange);
    //     };
    // }, []);

    const isLandingPage = location.pathname === '/' && !user.login;
    
    return (
        <nav className="nav">
            <div className="nav-left" onClick={() => navigate(`/`)}>
                <div className="nav-left-icon"></div>
                <h1 className="nav-left-name">BokenMap</h1>
            </div>
            <div className="nav-right">
                {isLandingPage && (
                    <div className='nav-right-theme-toggle-container' onClick={()=>{setActiveTheme(inactiveTheme)}}>
                        {theme === "dark" ? <FontAwesomeIcon icon={faBolt} className='nav-right-theme-toggle' /> : <FontAwesomeIcon icon={faMoon} className='nav-right-theme-toggle' />}
                    </div>
                    // <ThemeToggle setTheme={setTheme} className="theme-toggle"></ThemeToggle>
                )}
                {loadingUser ? (
                    <div></div>
                ) : (
                    user.login === true ? (
                        <>
                            <img onClick={toggleAccountMenu} className='nav-right-account' src={user.user.image} ref={accountIconRef} alt='' />
                            {AccountMenu && (
                                <div ref={accountMenuRef} className="account-menu">
                                    <div onClick={() => { navigate(`/${user.user.handle}`); setAccountMenu(false) }} className='account-menu-option'>Profile</div>
                                    <div onClick={() => { navigate(`/dashboard/settings`); setAccountMenu(false) }} className='account-menu-option'>Settings</div>
                                    <div onClick={() => { navigate(`/dashboard/plus`); setAccountMenu(false) }} className='account-menu-option'>Plus</div>
                                    <div className='liner'></div>
                                    <div onClick={handleLogout} className='account-menu-option'>Logout</div>
                                </div>
                            )}
                        </>
                    ) : (
                        // <a href={`${process.env.REACT_APP_BACK_DOMAIN}/auth/google`} className="nav-right-login">Login</a>
                        <div onClick={handleModalOpen} className="nav-right-login">Login</div>
                    )
                )}
            </div>
        </nav>
    );
}

export default Navbar;
