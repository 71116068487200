import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';

// import { Skeleton } from '@mui/material';

import HomeProfile from "../components/HomeProfile";
import Error from '../components/Error';

function Home({ user, mapColor }) {
    const [homeUser, setHomeUser] = useState(null);
    const [queryTab, setQueryTab] = useState(null);
    const [loading, setLoading] = useState(true);

    const queries = new URLSearchParams(useLocation().search);
    const queryType = queries.get("type");

    const paramsHandle = useParams().handle;
    const handle = paramsHandle.startsWith("@") ? paramsHandle.slice(1) : paramsHandle;

    useEffect(() => {
        if (queryType === "country" || queryType === "city") {
            setQueryTab(queryType);
        }
    }, [queryType]);

    useEffect(() => {
        if (!window.localStorage.getItem("bokenmap-darkmode-theme")) {
            window.localStorage.setItem("bokenmap-darkmode-theme", "dark");
        }

        setLoading(true);  // Start loading before fetch
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${handle}`, { method: "GET", credentials: "include" })
            .then(response => response.json())
            .then(data => {
                if (data.exist) {
                    setHomeUser(data);
                } else {
                    setHomeUser(null);
                }
            }).catch(error => {
                console.error(error);
                setHomeUser(null);
            }).finally(() => {
                setLoading(false);  // End loading after fetch
            });
    }, [handle]);

    return (
        <div>
            <Helmet>
                <title>{homeUser ? `${homeUser.homeUser.username} | BokenMap` : "BokenMap"}</title>
            </Helmet>

            {loading ? (
                <div className='skeleton-page' style={{height: "100vh"}}>
                  {/* <div className='skeleton-map-container'>
                    <Skeleton variant="rounded" width="100%" height="100%" className='skeleton-map' />
                  </div>
                  <div className='skeleton-bar'>
                    <Skeleton variant="circular" width={100} height={100} className='skeleton-image' />
                    <Skeleton variant="rounded" width={300} height={100} className='skeleton-data' />
                  </div> */}
                </div>
            ) : (
                homeUser && (!homeUser.homeUser.private || homeUser.match) ? (
                    <HomeProfile user={user} homeUser={homeUser} queryTab={queryTab} mapColor={mapColor} />
                ) : (
                    <Error />
                )
            )}
            {/* <div style={{height: "500px"}}>
                  <Skeleton variant="rounded" animation="wave" width={210} height={118} />
                </div> */}
        </div>
    );
}

export default Home;
