
import {React, useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useNavigate, useLocation, Navigate, Link } from 'react-router-dom';

import "../../stylesheets/Info.css"


function Info({}) {
    const navigate = useNavigate();
    const location = useLocation();


    // useEffect(() => {
    //     const path = location.pathname.split('/').pop();
    //     setSelectedOption(path || ''); // Set based on URL, or empty if at root
    // }, [location]);


    // const handleSelectChange = (option) => {
    //     const selectedPath = option?.value;
    //     setSelectedOption(selectedPath);
    //     if (selectedPath) {
    //         navigate(selectedPath); // Navigate to the selected route
    //     }
    // };



    return (
        <div className='page'>
            <Helmet>
                <title>Info | BokenMap</title>
            </Helmet>

            <h1>Info</h1>
            <div style={{display: "flex", flexDirection: "column", marginTop: "10px"}}>
                <Link to="/about">About</Link>
                <div style={{marginTop: "10px"}}></div>
                <Link to="/info/category" className='info-link-each'>Category</Link>
                <Link to="/info/whatsacountry" className='info-link-each'>What's a Country?</Link>
                <Link to="/info/extra" className='info-link-each'>Extra</Link>
                <div style={{marginTop: "10px"}}></div>
                <Link to="/terms" className='info-link-each'>Terms</Link>
                <Link to="/privacy" className='info-link-each'>Privacy</Link>
                <Link to="/commerce" className='info-link-each'>Commerce</Link>
                <div style={{marginTop: "10px"}}></div>
                <Link to="/pricing" className='info-link-each'>Pricing</Link>
                <div style={{marginTop: "10px"}}></div>
                <Link to="/support" className='info-link-each'>Support</Link>
            </div>

            

            {/* <div>
                <Routes>
                    <Route path="category" element={<Category />} />
                    <Route path="whatsacountry" element={<WhatsaCountry />} />
                    <Route path="extra" element={<Extra />} />
                </Routes>
            </div> */}


        </div>
    );
}

export default Info;