import React from 'react';
import { Helmet } from 'react-helmet-async';

function Category() {
    return (
        <div className='page'>
            <Helmet>
                <title>Category | BokenMap</title>
            </Helmet>
            <h1>The 3 Categories</h1>

            <h2>What are the 3 status categories?</h2>
            <p><b>Lived:</b> Countries/cities you have lived in.</p>
            <p><b>Visited:</b> Countries/cities you have traveled to.</p>
            <p><b>Transited:</b> Countries/cities you have transited, whether through an airport, road, or other.</p>
            <p>There are no clear guidelines for these categories, so you can make up your own rules. For example, "Lived" could be over a month of residence, "Transited" could be a visit within 24 hours, and anything in between could go in "Visited". Another distinction between "Visited" and "Transited" could be based on entry to a country, meaning whether you passed through immigration/customs or not. A year of residence might be a better requirement of "Lived" for some people. Have your own definition.</p>

            <h2>What are the 3 default status colors?</h2>
            <p style={{display: "flex", alignItems: "center"}}><b>Lived:</b><span style={{width: "15px", height: "15px", backgroundColor: "#005403", borderRadius: "50px", marginLeft: "10px", marginRight: "5px"}}></span> #005403</p>
            <p style={{display: "flex", alignItems: "center"}}><b>Visited:</b><span style={{width: "15px", height: "15px", backgroundColor: "#00cf18", borderRadius: "50px", marginLeft: "10px", marginRight: "5px"}}></span> #00cf18</p>
            <p style={{display: "flex", alignItems: "center"}}><b>Transited:</b><span style={{width: "15px", height: "15px", backgroundColor: "#bbff00", borderRadius: "50px", marginLeft: "10px", marginRight: "5px"}}></span> #bbff00</p>
            <p>This is just the default, and you can change the theme by subscribing to <a href="/pricing">Plus</a>.</p>

            <h2>Which catagories are counted in the data?</h2>
            <p>"Lived" and "Visited" countries/cities are counted in your stats. "Transited" countries/cities are not included.</p>


        </div>
    );
}

export default Category;