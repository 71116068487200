import React from 'react';
import { Helmet } from 'react-helmet-async';

function Support() {
    return (
        <div className='page'>
            <Helmet>
                <title>Support | BokenMap</title>
            </Helmet>
            <h1>Support</h1>

        </div>
    );
}

export default Support;