import React from 'react';
import { Helmet } from 'react-helmet-async';

function WhatsaCountry() {
    return (
        <div className='page'>
            <Helmet>
                <title>What is a Country? | BokenMap</title>
            </Helmet>
            <h1>What is a Country?</h1>

            <h2>What classifies as "country"?</h2>
            <p>The number shown on your profile is based on <a href="https://en.wikipedia.org/wiki/Member_states_of_the_United_Nations" target="_blank" rel='noreferrer'>United Nations membership states</a>. There are 193 in total, and the number of countries for each continent is based on this number as well. You can add non-UN states/regions to your list and on the map, but they will not count in the numerical stats shown on top. The total number of countries/regions available is 251.</p>
            <p>FYI, these are some famous/major states that are not UN members.</p>
            <ul class="non-un-list">
                <li>Taiwan (Republic of China)</li>
                <li>Vatican City</li>
                <li>State of Palestine</li>
                <li>Kosovo</li>
                <li>Cook Islands</li>
                <li>Western Sahara (Sahrawi Arab Democratic Republic)</li>
                <li>Niue</li>
            </ul>
            <p>By the way, below are some of the regions that you can add to your list, but aren't countries.</p>
            <ul>
                <li><span>Hong Kong</span> &nbsp; (Special Administrative Region of the People's Republic of China)</li>
                <li><span>Macao</span> &nbsp; (Special Administrative Region of the People's Republic of China)</li>
                <li><span>French Guiana</span> &nbsp; (Overseas department of France)</li>
                <li><span>Gibraltar</span> &nbsp; (British Overseas Territory)</li>
                <li><span>Puerto Rico</span> &nbsp; (Unincorporated and organized U.S. territory)</li>
                <li><span>Greenland</span> &nbsp; (Autonomous territory in the Kingdom of Denmark)</li>
                <li><span>Antarctica</span> &nbsp; (Antarctic Treaty System)</li>
            </ul>
            <p>JSON data.</p>
            <ul>
                <li><a href="/api/data/country/full">Available countries/regions (json) 251</a></li>
                <li><a href="/api/data/country/un">Available UN countries (json) 193</a></li>
            </ul>

            <h2>Which catagories are counted in the data?</h2>
            <p>"Lived" and "Visited" countries/cities are counted in your stats. "Transited" countries/cities are not included.</p>


        </div>
    );
}

export default WhatsaCountry;