import React from 'react';
import { Helmet } from 'react-helmet-async';

function Contact() {
    return (
        <div className='page'>
            <Helmet>
                <title>Contact | BokenMap</title>
            </Helmet>
            <h1>Contact</h1>

            <div style={{backgroundColor: "#f5f4f2", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "700px", margin: "auto"}}>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc-TBmt3oeH7J8obMdKkuabTLdP9DlnyOlEfJF7Z4pJujdMdA/viewform?embedded=true&amp;hl=en" width="100%" height="640px" frameborder="0" marginheight="0" marginwidth="0"></iframe>
            </div>

        </div>
    );
}

export default Contact;



